import ErrorCode from '../../shared/ErrorCode';
import Alert from 'react-bootstrap/Alert';
import BrainfuckMemoryTape from './BrainfuckMemoryTape';

function renderIf(cond, component) {
    if (cond) {
        return component;
    }
    return <></>;
}

function messageForErrorCode(errorCode) {
    let message = '';
    if (errorCode === ErrorCode.COMPILATION) {
        message = 'Syntax Error';
    } else if (errorCode === ErrorCode.MEMORY) {
        message = 'Memory Overflow';
    } else if (errorCode === ErrorCode.RUNTIME) {
        message = 'Runtime Error';
    } else if (errorCode === ErrorCode.TIMEOUT) {
        message = 'Timeout';
    } else if (errorCode === ErrorCode.WRONG_ANSWER) {
        message = 'Wrong Answer';
    } else if (errorCode === ErrorCode.SERVER) {
        message = 'A server error has occurred';
    } else {
        message = 'An unkown error has occurred';
    }

    return message;
}

function SampleTestResult(props) {
    let {
        testId,
        errorCode,
        errorMessage,
        input,
        inputMem,
        output,
        outputMem,
        expectedOutput,
        expectedOutputMem,
    } = props;

    if (errorCode === ErrorCode.SUCCESS) {
        return <h4>Sample {testId} Passed</h4>;
    }

    let message = messageForErrorCode(errorCode);

    return (
        <>
            <h4>
                Sample {testId} - {message}
            </h4>

            {renderIf(input || inputMem, <h5>Input</h5>)}
            {renderIf(inputMem, <BrainfuckMemoryTape mem={inputMem} />)}
            {renderIf(
                input,
                <pre className="brainfuck-io-output">{input}</pre>
            )}

            {renderIf(output || output === '' || outputMem, <h5>Output</h5>)}
            {errorMessage ? (
                <Alert className="brainfuck-error-dialog" variant="danger">
                    {errorMessage}
                </Alert>
            ) : (
                <>
                    {renderIf(
                        outputMem,
                        <BrainfuckMemoryTape mem={outputMem} />
                    )}
                    {renderIf(
                        output || output === '',
                        <pre className="brainfuck-io-output">{output}</pre>
                    )}
                </>
            )}

            {renderIf(expectedOutput || expectedOutputMem, <h5>Expected</h5>)}
            {renderIf(
                expectedOutputMem,
                <BrainfuckMemoryTape mem={expectedOutputMem} />
            )}
            {renderIf(
                expectedOutput,
                <pre className="brainfuck-io-output">{expectedOutput}</pre>
            )}
        </>
    );
}

function HiddenTestResult(props) {
    let { testId, errorCode } = props;

    if (errorCode === ErrorCode.SUCCESS) {
        return <h4>Test {testId} Passed</h4>;
    }

    let message = messageForErrorCode(errorCode);

    return (
        <h4>
            Test {testId} - {message}
        </h4>
    );
}

function BrainfuckSubmissionResults(props) {
    return (
        <div className="brainfuck-submission-results">
            {props.solved ? (
                <h2>Congratulations, all tests passed!</h2>
            ) : (
                <>
                    {props.sampleTestResults.map((res, index) => (
                        <SampleTestResult key={index} testId={index} {...res} />
                    ))}

                    {props.testResults.map((res, index) => (
                        <HiddenTestResult key={index} testId={index} {...res} />
                    ))}
                </>
            )}
        </div>
    );
}

export default BrainfuckSubmissionResults;
