import axios from 'axios';

function setAuthToken(token) {
    if (token) {
        // add auth token to all requests while logged in
        axios.defaults.headers.common['Authorization'] = token;
    } else {
        // delete if no token
        delete axios.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken;
