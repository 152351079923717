import {
    GET_MODULES,
    SELECT_MODULE,
    GET_MODULE,
    SELECT_PROBLEM,
    GET_PROBLEM,
} from '../actions/types';
import getFullProblemId from '../shared/getFullProblemId';
import _ from 'lodash';

const initialState = {
    modules: [], // all available challenge modules
    selectedModule: null, // currently selected module (or null if none)
    selectedProblem: null, // currently selected problem (or null if none)
    moduleMap: {}, // mapping of moduleId -> module
    problemMap: {}, // mapping of fullProblemId -> problem
};

function challengesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_MODULES: {
            let { modules } = action,
                moduleMap = _.cloneDeep(state.moduleMap);

            // add any modules that have not yet been seen to the moduleMap
            for (let mod of modules) {
                if (!moduleMap[mod.id]) {
                    moduleMap[mod.id] = mod;
                }
            }

            return {
                ...state,
                modules,
                moduleMap,
            };
        }
        case SELECT_MODULE: {
            let { moduleId } = action,
                mod = state.moduleMap[moduleId] || null;

            return {
                ...state,
                selectedModule: mod,
            };
        }
        case GET_MODULE: {
            let { moduleId, name, collections } = action,
                { selectedModule } = state,
                moduleMap = _.cloneDeep(state.moduleMap);

            moduleMap[moduleId] = {
                name,
                id: moduleId,
                collections,
            };

            if (selectedModule === null || selectedModule.id === moduleId) {
                selectedModule = moduleMap[moduleId];
            }

            return {
                ...state,
                moduleMap,
                selectedModule,
            };
        }
        case SELECT_PROBLEM: {
            let { moduleId, collectionId, problemId } = action,
                fullProblemId = getFullProblemId(
                    moduleId,
                    collectionId,
                    problemId
                ),
                problem = state.problemMap[fullProblemId] || null;

            return {
                ...state,
                selectedProblem: problem,
            };
        }
        case GET_PROBLEM: {
            let { problem } = action,
                { selectedProblem } = state,
                problemMap = _.cloneDeep(state.problemMap),
                fullProblemId = getFullProblemId(
                    problem.moduleId,
                    problem.collectionId,
                    problem.problemId
                );

            problemMap[fullProblemId] = problem;

            if (
                selectedProblem === null ||
                selectedProblem.fullProblemId === fullProblemId
            ) {
                selectedProblem = problem;
                selectedProblem.fullProblemId = fullProblemId;
            }

            return {
                ...state,
                selectedProblem,
                problemMap,
            };
        }
        default:
            return state;
    }
}

export default challengesReducer;
