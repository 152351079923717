import 'ace-builds/src-noconflict/mode-text';

export class IntercalHighlightRules extends window.ace.acequire(
    'ace/mode/text_highlight_rules'
).TextHighlightRules {
    constructor() {
        super();

        const keywordMapper = this.createKeywordMapper(
            {
                keyword:
                    'DO|PLEASE|NOT|SUB|NEXT|RESUME|FORGET|STASH|RETRIEVE|' +
                    'IGNORE|REMEMBER|ABSTAIN|FROM|REINSTATE|READ|OUT|WRITE|IN|COME|GIVE|UP',
            },
            'identifier'
        );

        this.$rules = {
            start: [
                {
                    token: 'keyword',
                    regex: /N'T/,
                },
                {
                    token: keywordMapper,
                    regex: /[A-Z]+/,
                },
                {
                    token: 'constant.numeric',
                    regex: /\d+/,
                },
                {
                    token: 'punctuation.operator',
                    regex: /[.:,;#='!?"|%-<>()[\]{}*&V$~_¯+/\\@]/,
                },
            ],
        };
    }
}

export default class IntercalMode extends window.ace.acequire('ace/mode/text')
    .Mode {
    constructor() {
        super();
        this.HighlightRules = IntercalHighlightRules;
    }
}
