import { storageFactory } from 'storage-factory'; // https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/

/*
TODO:
    - handle out of memory issues
    - should it be tied to current user? (currently, logging out user1 -> log in as user2 keeps user1's code)
*/
const LocalStorage = storageFactory(() => localStorage);

export default LocalStorage;
