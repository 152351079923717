import 'ace-builds/src-noconflict/mode-text';

const measure_types = 'heaped|level';
const measures =
    'g|kg|pinches|pinch|ml|l|dashes|dash|cups|cup|teaspoons|teaspoon|tablespoons|tablespoon';

function operatorRules(op, ...dividers) {
    let rules = [];
    for (let divider of dividers) {
        if (divider === null) {
            // ingredient is last word in sentence
            rules.push({
                token: ['operator', 'ingredient', 'text'],
                regex: `(${op} )([^.]+?)(\\.\\s*)`,
                next: 'method',
            });
        } else {
            // ingredient is followed by `divider`
            rules.push({
                token: ['operator', 'ingredient', 'text'],
                regex: `(${op} )([^.]+?)( ${divider})(?=.*?\\.\\s*)`,
                next: 'method',
            });
        }
    }

    // unfinished sentence
    // also seems to handle some other cases
    rules.push({
        token: ['operator', 'ingredient', 'text'],
        regex: `(${op} )([^.]+?)(\\s+)`,
        next: 'method',
    });

    return rules;
}

export class ChefHighlightRules extends window.ace.acequire(
    'ace/mode/text_highlight_rules'
).TextHighlightRules {
    constructor() {
        super();
        this.$rules = {
            start: [
                {
                    token: 'title',
                    regex: /.*\./,
                    next: 'ingredients_header',
                },
            ],

            ingredients_header: [
                {
                    token: 'header',
                    regex: /Ingredients\.\s*$/,
                    next: 'ingredients',
                },
            ],

            ingredients: [
                {
                    token: 'text',
                    regex: '^\\s*$',
                    next: 'notes',
                },
                {
                    token: 'header',
                    regex: 'Method\\.\\s*$',
                    next: 'method',
                },
                {
                    token: 'ingredient-amount',
                    regex: '\\d+ ',
                },
                {
                    token: 'ingredient-measure',
                    regex: `(${measure_types}) (${measures}) `,
                    next: 'ingredient',
                },
                {
                    token: 'ingredient-measure',
                    regex: `${measures} `,
                    next: 'ingredient',
                },
                {
                    token: 'ingredient',
                    regex: '.*$',
                    next: 'ingredients',
                },
            ],

            ingredient: [
                {
                    token: 'ingredient',
                    regex: `.+$`,
                    next: 'ingredients',
                },
            ],

            // for cooking time, oven temperature
            notes: [
                {
                    token: 'header',
                    regex: 'Method\\.\\s*$',
                    next: 'method',
                },
                {
                    token: 'text',
                    regex: '.*',
                },
            ],

            method: [
                ...operatorRules('Take', 'from'),
                ...operatorRules('Put', 'into'),
                ...operatorRules('Fold', 'into'),
                ...operatorRules('Add', 'to', null),
                ...operatorRules('Remove', 'from', null),
                ...operatorRules('Combine', 'into', null),
                ...operatorRules('Divide', 'into', null),
                {
                    token: ['operator', 'text'],
                    regex:
                        '(Liquefy )(contents of the [^.]*?mixing bowl\\.\\s*)',
                    next: 'method',
                },
                ...operatorRules('Liquefy', null), // some versions would accept Liquify too
                ...operatorRules('Stir', 'into'),
                {
                    token: ['operator', 'recipe'],
                    regex: '(Serve with )([^.]+?)(?=\\.\\s*)',
                    next: 'method',
                },
                {
                    token: 'text',
                    regex: '.+?\\.\\s*(?=.)',
                    next: 'method',
                },
                {
                    token: 'text',
                    regex: '\\.\\s*$',
                    next: 'serves_segue',
                },
            ],

            // checks if method section is over
            serves_segue: [
                {
                    token: 'text',
                    regex: '^\\s*$',
                    next: 'serves',
                },
                {
                    token: 'text',
                    regex: '',
                    next: 'method',
                },
            ],

            serves: [
                {
                    token: 'serves',
                    regex: '\\s*Serves .*?\\.',
                    next: 'start', // back to start for auxiliary recipes
                },
                {
                    token: 'text',
                    regex: '.*',
                    next: 'serves',
                },
            ],
        };
    }
}

export default class ChefMode extends window.ace.acequire('ace/mode/text')
    .Mode {
    constructor() {
        super();
        this.HighlightRules = ChefHighlightRules;
    }
}
