import './befunge-tutorial.css';

const instructions = [
    [
        ['0-9', 'Push: push corresponding digit onto the stack'],
        [
            '"',
            'Toggle Stringmode: while in Stringmode, push each character\'s ASCII value until next " is encountered',
        ],
    ],
    [
        ['+', 'Addition: pop a,b then push b+a'],
        ['-', 'Subtraction: pop a,b then push b-a'],
        ['*', 'Multiplication: pop a,b then push b*a'],
        ['/', 'Integer Division: pop a,b then push quotient of b/a'],
        ['%', 'Modulo: pop a,b then push remainder of b/a'],
    ],
    [
        ['!', 'Logical NOT: pop a then if a = 0 push 1, otherwise push 0'],
        ['`', 'Greater Than: pop a,b then if b > a push 1, otherwise push 0'],
    ],
    [
        ['>', 'set IP direction right'],
        ['<', 'set IP direction left'],
        ['^', 'set IP direction up'],
        ['v', 'set IP direction down'],
        ['?', 'set IP to random direction'],
    ],
    [
        [
            '_',
            'Horizontal IF: pop a then if a=0 set PC right, otherwise set PC left',
        ],
        [
            '|',
            'Vertical IF: pop a then if a=0 set PC down, otherwise set PC up',
        ],
        ['#', 'Bridge: jump over the next instruction in current direction'],
    ],
    [
        [':', 'Duplicate: duplicate the top value on the stack'],
        ['\\', 'Swap: swap the top two values on the stack'],
        ['$', 'Pop: remove the top value on the stack and discard it'],
    ],
    [
        ['&', 'Input (int): read an integer and push it to the stack'],
        [
            '~',
            "Input (char): read a character and push its ASCII value to the stack",
        ],
        ['.', 'Output (int): pop the top value and output as an integer'],
        [
            ',',
            'Output (char): pop the top value and output as an ASCII character',
        ],
    ],
    [
        ['g', 'Get: pop y,x then push ASCII value of character at (x,y)'],
        [
            'p',
            'Put: pop y,x,v then put the charcter with ASCII value v at (x,y)',
        ],
    ],
    [['@', 'Terminate program']],
];

function BefungeInstructionTable() {
    return (
        <div className="befunge-instructions-table">
            <table className="table">
                <thead>
                    <tr>
                        <th>Cmd</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <>
                    {instructions.map((section, bodyIndex) => (
                        <tbody key={bodyIndex}>
                            {section.map(([cmd, description], rowIndex) => (
                                <tr key={rowIndex}>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        {cmd}
                                    </td>
                                    <td>{description}</td>
                                </tr>
                            ))}
                        </tbody>
                    ))}
                </>
            </table>
        </div>
    );
}

export default BefungeInstructionTable;
