import './cards.css';
import React from 'react';
import { Link } from 'react-router-dom';

class EsoDeck extends React.Component {
    render() {
        const { className, ...remainingProps } = { ...this.props };

        return (
            <div
                className={`eso-deck ${className || ''}`}
                {...remainingProps}
            />
        );
    }

    static Card = (props) => {
        const { className, linkTo, ...remainingProps } = props;

        return (
            <Link
                className={`eso-card ${className || ''}`}
                {...remainingProps}
                to={linkTo}
            >
                <h1>{props.name}</h1>
            </Link>
        );
    };
}

export default EsoDeck;
