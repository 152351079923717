import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <Navbar id="footer" expand="sm" variant="light">
            <Nav className="ml-auto mr-auto">
                {/* <Nav.Link as={Link} to="/about">
                    about
                </Nav.Link> */}
                <Nav.Link href="mailto:hello@esolab.org" target="_blank">
                    contact us
                </Nav.Link>
                <Nav.Link as={Link} to="/feedback">
                    feedback
                </Nav.Link>
            </Nav>
        </Navbar>
    );
}

export default Footer;
