import { SET_CURRENT_USER, USER_LOADING } from '../actions/types';

const initialState = {
    isAuthenticated: false,
    user: null,
    loading: false,
};

function authReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: action.payload !== null,
                user: action.payload,
            };
        case USER_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}

export default authReducer;
