function keyEventOnlyNumbers(e) {
    let keyCode = e.keyCode || e.charCode;
    if (keyCode < 48 || keyCode > 57) {
        e.preventDefault();
    }
}

/**
 * A component representing a Brainfuck memory tape.
 * @param {{mem: [string],
 *          selectedIndex: number,
 *          disabled: boolean,
 *          isInput: boolean,
 *          onCellChange: (index: number, value: number) => void}} props
 */
function BrainfuckMemoryTape(props) {
    let mem = props.mem || [],
        size = mem.length,
        selectedIndex = props.selectedIndex,
        isInput = props.isInput || false,
        onCellChange = props.onCellChange || (() => {}),
        headers = [],
        cells = [];

    if (!selectedIndex && selectedIndex !== 0) {
        selectedIndex = -1;
    }

    for (let i = 0; i < size; i++) {
        headers.push(<th key={i}>{i}</th>);
    }

    if (isInput) {
        for (let i = 0; i < size; i++) {
            let value = mem[i];
            if (value === 0) {
                value = '';
            }

            let onChange = (e) => {
                let value = e.target.value;
                onCellChange(i, value);
            };

            let cell = (
                <td key={i}>
                    <input
                        type="text"
                        className="brainfuck-memory-tape-input-area"
                        maxLength={3}
                        value={value}
                        placeholder="0"
                        disabled={props.disabled}
                        onChange={onChange}
                        onKeyPress={keyEventOnlyNumbers}
                        onPaste={(e) => e.preventDefault()}
                    />
                </td>
            );
            cells.push(cell);
        }
    } else {
        for (let i = 0; i < size; i++) {
            let cell;
            if (selectedIndex === i) {
                cell = (
                    <td key={i} className="brainfuck-memory-tape-selected-cell">
                        {mem[i]}
                    </td>
                );
            } else {
                cell = <td key={i}>{mem[i]}</td>;
            }
            cells.push(cell);
        }
    }

    return (
        <div className="brainfuck-memory-tape-wrapper">
            <table className="brainfuck-memory-tape">
                <thead>
                    <tr>{headers}</tr>
                </thead>
                <tbody>
                    <tr>{cells}</tr>
                </tbody>
            </table>
        </div>
    );
}

export default BrainfuckMemoryTape;
