import axios from 'axios';
import { setErrors } from './errorActions';
import {
    GET_MODULES,
    SELECT_MODULE,
    GET_MODULE,
    SELECT_PROBLEM,
    GET_PROBLEM,
} from './types';

export const getModules = () => (dispatch) => {
    axios.get('/problems/modules').then((res) => {
        const modules = res.data;

        dispatch({
            type: GET_MODULES,
            modules,
        });
    });
};

export const selectModule = (moduleId) => (dispatch) => {
    dispatch({
        type: SELECT_MODULE,
        moduleId,
    });

    getModule(moduleId)(dispatch);
};

const getModule = (moduleId) => (dispatch) => {
    axios
        .get(`/problems/module/${moduleId}`)
        .then((res) => {
            const { name, collections } = res.data;

            const allCollection = { name: 'All', id: 'all', problems: [] };
            for (let collection of collections) {
                if (collection.id === 'tutorial') {
                    collection.problems = [
                        {
                            name: 'Tutorial',
                            isTutorial: true,
                            moduleId,
                            collectionId: collection.id,
                            solved: collection.completed,
                        },
                    ];
                }
                allCollection.problems.push(...collection.problems);
            }
            dispatch({
                type: GET_MODULE,
                moduleId,
                name,
                collections: [allCollection, ...collections],
            });
        })
        .catch((err) => {
            dispatch(setErrors({ selectModule: true }));
        });
};

export const selectProblem = (moduleId, collectionId, problemId) => (
    dispatch
) => {
    dispatch({
        type: SELECT_PROBLEM,
        moduleId,
        collectionId,
        problemId,
    });

    getProblem(moduleId, collectionId, problemId)(dispatch);
};

const getProblem = (moduleId, collectionId, problemId) => (dispatch) => {
    axios
        .get(`/problems/problem/${moduleId}/${collectionId}/${problemId}`)
        .then((res) => {
            dispatch({
                type: GET_PROBLEM,
                problem: res.data,
            });
        })
        .catch((err) => {
            dispatch(setErrors({ selectProblem: true }));
        });
};
