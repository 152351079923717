import { TutorialSection, TutorialPage } from '../Tutorial';
import BefungeInstructionTable from './BefungeInstructionTable';
import BefungeTutorialEditor from './BefungeTutorialEditor';

const exampleCode = '"!dlroW ,olleH">:#,_@';

function BefungeTutorialIntroduction() {
    return (
        <TutorialSection key="introduction">
            <TutorialPage title="Introduction">
                <p>
                    Befunge is a multi-dimensional language created by Chris
                    Pressey in 1993 with the goal of being difficult to compile.
                </p>
                <p>
                    It is considered a two-dimensional language because all code
                    is written within an 80x25 grid of single-byte cells called
                    the playfield, and the program counter (PC) has
                    two-dimensional directionality. Each cell in the grid
                    contains an ASCII character representing an instruction. The
                    program is interpreted by starting at the top left corner
                    reading one instruction left-to-right, but the PC can be
                    rotated to read right-to-left or even up or down.
                    Furthermore, the PC wraps around the edges of the playfield
                    when it reaches the end (or front, depending on direction)
                    of a row or column.
                </p>

                <p>
                    Here’s a summary of all of the instructions in Befuge. We'll
                    go into more details later, but it's a good overview of the
                    language and can be useful reference when coding. Any
                    character not listed in this table is treated as a NOP (it
                    does nothing).
                </p>

                <BefungeInstructionTable />

                {/* <table className="table">
                    <thead>
                        <tr>
                            <th>Cmd</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <>
                        {instructions.map((section, bodyIndex) => (
                            <tbody key={bodyIndex}>
                                {section.map(([cmd, description], rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td style={{ textAlign: 'center' }}>
                                            {cmd}
                                        </td>
                                        <td>{description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        ))}
                    </>
                </table> */}

                <p>
                    Try running the example code below! You can click the ‘Run’
                    button to execute the full program or ‘Debug’ to step
                    through the code yourself or watch an animated step-through.
                </p>

                <BefungeTutorialEditor
                    editorId="befunge-tutorial-instructions"
                    defaultCode={exampleCode}
                />
                <b>References</b>
                <p>
                    <a
                        href="https://esolangs.org/wiki/Befunge"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://esolangs.org/wiki/Befunge
                    </a>
                    <br />
                    <a
                        href="https://catseye.tc/view/Befunge-93/doc/Befunge-93.markdown"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://catseye.tc/view/Befunge-93/doc/Befunge-93.markdown
                    </a>
                </p>
            </TutorialPage>
        </TutorialSection>
    );
}

export default BefungeTutorialIntroduction;
