import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const idFn = () => {};

class ToggleSwitch extends React.Component {
    constructor(props) {
        super(props);

        this.modes = props.modes;
        this.onToggle = props.onToggle || idFn;
        this.className = props.className;

        this.state = {
            selectedMode: this.modes[0].value,
        };
    }

    render() {
        let selectedMode = this.props.selectedMode || this.state.selectedMode;

        return (
            <ButtonGroup toggle className={this.className}>
                {this.modes.map((mode, i) => {
                    let isSelected = selectedMode === mode.value;

                    let onChange = (e) => {
                        this.onToggle(mode);

                        this.setState({ selectedMode: mode.value }, () =>
                            e.target.blur()
                        );
                    };
                    return (
                        <ToggleButton
                            key={i}
                            type="checkbox"
                            variant={isSelected ? 'dark' : 'light'}
                            checked={isSelected}
                            value={mode.value}
                            onChange={onChange}
                        >
                            {mode.name}
                        </ToggleButton>
                    );
                })}
            </ButtonGroup>
        );
    }
}

export default ToggleSwitch;
