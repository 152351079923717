import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import GravitySimulation from '../interpreters/gravity/GravitySimulation';
import IntercalCharacterTable from '../interpreters/intercal/IntercalCharacterTable';
import EsoDeck from '../ui-components/cards/EsoDeck';
import BefungeInstructionTable from '../tutorials/befunge/BefungeInstructionTable';

const miscPage = (name, path, component) => {
    return { name, path: `/misc/${path}`, component };
};

const miscPages = [
    miscPage(
        'Befunge Instruction Table',
        'befunge-instruction-table',
        BefungeInstructionTable
    ),
    miscPage(
        'INTERCAL Character Table',
        'intercal-character-table',
        IntercalCharacterTable
    ),
    miscPage('Gravity Simulation', 'gravity-simulation', GravitySimulation),
];

function withRoutes(Component) {
    return () => (
        <Switch>
            {miscPages.map((page, index) => (
                <Route
                    key={index}
                    exact
                    path={page.path}
                    component={page.component}
                />
            ))}

            <Route exact path="/misc" component={Component} />
            <Redirect to="/misc" />
        </Switch>
    );
}

function MiscellaneousPage() {
    return (
        <div className="page-body">
            <Helmet>
                <title>Misc | EsoLab</title>
            </Helmet>

            <h1>Miscellaneous</h1>

            <EsoDeck style={{ marginTop: '0.5rem' }}>
                {miscPages.map((page, index) => (
                    <EsoDeck.Card
                        key={index}
                        name={page.name}
                        linkTo={page.path}
                    />
                ))}
            </EsoDeck>
        </div>
    );
}

export default withRoutes(MiscellaneousPage);
