import './befunge.css';

import BefungeIDE from './BefungeIDE';
import { Helmet } from 'react-helmet-async';

function BefungeSandbox() {
    return (
        <div className="befunge-sandbox-container page-body">
            <Helmet>
                <title>Befunge | EsoLab</title>
            </Helmet>

            <h1 className="text-center">Befunge</h1>

            <BefungeIDE editorId="sandbox" />
        </div>
    );
}

export default BefungeSandbox;
