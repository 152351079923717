import React from 'react';
import BrainfuckIDE from '../../interpreters/brainfuck/BrainfuckIDE';
import Container from 'react-bootstrap/Container';
import BrainfuckInterpreter from '../../interpreters/brainfuck/BrainfuckInterpreter';

class BrainfuckTutorialEditor extends React.Component {
    /**
     * Keeps track of editor states for the brainfuck tutorial so it can switch between pages.
     * @name BrainfuckTutorialEditor#editorStates
     * @type {{[editorId: string]: any}}
     */
    static editorStates = {};

    /**
     *
     * @param {{
     *      editorId: string?,
     *      problemId: string?,
     *      defaultCode: string?,
     *      defaultInputMode: number,
     *      defaultInput: string?,
     *      defaultInputMem: [number]?,
     *      markComplete: (results: any) => void
     * }} props
     */
    constructor(props) {
        super(props);

        const {
            editorId,
            problemId,
            defaultCode,
            defaultInputMode,
            defaultInput,
            defaultInputMem,
            markComplete,
        } = props;

        this.editorId = editorId || problemId;
        this.problemId = problemId;

        this.ideProps = {
            editorId: this.editorId,
            defaultCode,
            defaultInputMode: defaultInputMode || BrainfuckIDE.IO_TAPE_MODE,
            defaultInputMem: BrainfuckInterpreter.sanitizeMemory(
                defaultInputMem || []
            ),
            defaultInput: defaultInput,
            submissionId: problemId,
            onSubmissionResults: (r) => {
                if (r.solved) {
                    markComplete(problemId);
                }
            },
        };

        this.editorRef = React.createRef();
    }

    render() {
        return (
            <Container>
                <BrainfuckIDE
                    ref={this.editorRef}
                    initState={
                        BrainfuckTutorialEditor.editorStates[this.problemId] ||
                        undefined
                    }
                    {...this.ideProps}
                />
            </Container>
        );
    }

    componentWillUnmount() {
        BrainfuckTutorialEditor.editorStates[
            this.editorId
        ] = this.editorRef.current.state;
    }
}

export default BrainfuckTutorialEditor;
