import React from 'react';
import { Tutorial } from '../Tutorial';

import './befunge-tutorial.css';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import BefungeTutorialIntroduction from './BefungeTutorialIntroduction';
class BefungeTutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completedSet: {},
        };
    }

    markComplete = (id) => {
        let completedSet = { ...this.state.completedSet };
        completedSet[id] = true;
        this.setState({ completedSet });
    };

    componentDidMount() {
        const completedSet = this.state.completedSet;

        axios.get('/problems/tutorial/befunge').then((res) => {
            const { problems } = res.data;

            for (let problem of problems) {
                if (problem.solved) {
                    const { moduleId, collectionId, problemId } = problem,
                        fullProblemId = `${moduleId}/${collectionId}/${problemId}`;
                    completedSet[fullProblemId] = true;
                }
            }

            this.setState({ completedSet });
        });
    }

    render() {
        let completedSet = this.state.completedSet,
            markComplete = this.markComplete;

        let sections = [BefungeTutorialIntroduction].map((Section) =>
            Section(completedSet, markComplete)
        );

        return (
            <>
                <Helmet>
                    <title>Befunge Tutorial | EsoLab</title>
                </Helmet>
                <Tutorial>{sections}</Tutorial>
            </>
        );
    }
}

export default BefungeTutorial;
