import axios from 'axios';
import React from 'react';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet-async';

function pad(x) {
    if (x < 10) return `0${x}`;
    else return x;
}

function formatDate(date) {
    if (date === null) {
        return '';
    }

    const d = new Date(date);

    const day = pad(d.getDate()),
        month = pad(d.getMonth() + 1),
        year = d.getFullYear(),
        hr = pad(d.getHours()),
        min = pad(d.getMinutes()),
        sec = pad(d.getSeconds());

    return `${year}-${month}-${day} ${hr}:${min}:${sec}`;
}

function ScoreTable(props) {
    return (
        <Table striped>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Username</th>
                    <th>Score</th>
                    <th>Last Solve Time</th>
                </tr>
            </thead>

            <tbody>
                {props.solvers.map((solver, i) => (
                    <tr key={i}>
                        <td>{solver.rank || '-'}</td>
                        <td>{solver.user.username}</td>
                        <td>{solver.score}</td>
                        <td>{formatDate(solver.latestTimestamp)}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

class Scoreboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            solvers: [],
            userScore: null,
            error: null,
        };
    }

    componentDidMount() {
        axios
            .get('/problems/scoreboard')
            .then((res) => {
                const { scoreboard, userScore } = res.data;
                this.setState({ solvers: scoreboard, userScore });
            })
            .catch(() => {
                this.setState({
                    error:
                        'An error occured while loading the scoreboard. Please try again.',
                });
            });
    }

    render() {
        const { solvers, userScore } = this.state;
        return (
            <div className="page-body">
                <Helmet>
                    <title>Scoreboard | EsoLab</title>
                </Helmet>

                <h2 className="scoreboard-header">Your Score</h2>
                <ScoreTable solvers={userScore !== null ? [userScore] : []} />

                <br />

                <h2 className="scoreboard-header">Scoreboard</h2>
                <ScoreTable solvers={solvers} />
            </div>
        );
    }
}

export default Scoreboard;
