import BrainfuckIDE from '../../interpreters/brainfuck/BrainfuckIDE';
import { TutorialSection, TutorialPage } from '../Tutorial';
import BrainfuckTutorialEditor from './BrainfuckTutorialEditor';
import Table from 'react-bootstrap/Table';

const ex1 = 'brainfuck/tutorial/output-exercise-1';

function ASCIITable(props) {
    let start = props.start || 0,
        end = props.end || 255,
        numCols = props.numCols,
        numRows = Math.ceil((end - start + 1) / numCols),
        rows = [];
    for (let i = 0; i < numRows; i++) {
        let cols = [];
        for (let j = 0; j < numCols; j++) {
            let charCode = j * numRows + i + start;
            if (charCode > end) {
                cols.push(<td key={2 * j}></td>);
                cols.push(<td key={2 * j + 1}></td>);
            } else {
                cols.push(
                    <td key={2 * j} style={{ fontWeight: 'bold' }}>
                        {charCode}
                    </td>
                );
                cols.push(
                    <td key={2 * j + 1}>{String.fromCharCode(charCode)}</td>
                );
            }
        }
        rows.push(<tr key={i}>{cols}</tr>);
    }

    return (
        <Table striped>
            <tbody>{rows}</tbody>
        </Table>
    );
}

function BrainfuckTutorialOutput(completedSet, markComplete) {
    return (
        <TutorialSection key="output">
            <TutorialPage title="Output">
                <p>
                    The '.' instruction lets you output a single character at a
                    time. Since all data in brainfuck is integers, it uses a
                    character encoding called ASCII to convert integers into
                    characters. For example, 65 is 'A', 66 is 'B', etc. while 97
                    is 'a', 98 is 'b', etc. The full conversion table is
                    included below. A more detailed version can be found{' '}
                    <a
                        href="https://www.ascii-code.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </p>

                <h5>Control Characters (printable)</h5>
                <p>
                    Character codes 0-31 are used to represent various control
                    commands. You will rarely come across most of them. The
                    important ones to know are 0 (null character - used by this
                    interpreter to mean end of input) and 10 (newline
                    character).
                </p>

                <h5>Printable Characters</h5>
                <ASCIITable numCols={8} start={32} end={127} />
                <p style={{ textAlign: 'center' }}>
                    (Note: 32 is space and 127 represents the delete command)
                </p>

                <h5>Extended ASCII</h5>
                <p>
                    Character codes 128-255 are known as extended ASCII codes,
                    and are mostly comprised of uncommon symbols
                </p>
                <ASCIITable numCols={8} start={128} end={255} />
            </TutorialPage>

            <TutorialPage title="Exercise 1" isCompleted={completedSet[ex1]}>
                <p>
                    Write a program that prints the word 'hi'. (hint: the
                    character code for 'h' is 104)
                </p>

                <BrainfuckTutorialEditor
                    key={ex1}
                    problemId={ex1}
                    markComplete={markComplete}
                    defaultInputMode={BrainfuckIDE.IO_TEXT_MODE}
                />
            </TutorialPage>
        </TutorialSection>
    );
}

export default BrainfuckTutorialOutput;
