import { TutorialSection, TutorialPage } from '../Tutorial';
import BrainfuckTutorialEditor from './BrainfuckTutorialEditor';

const ex1 = 'brainfuck/tutorial/loops-exercise-1';
const ex2 = 'brainfuck/tutorial/loops-exercise-2';
const ex3 = 'brainfuck/tutorial/loops-exercise-3';
const ex4 = 'brainfuck/tutorial/loops-exercise-4';

function BrainfuckTutorialLoops(completedSet, markComplete) {
    return (
        <TutorialSection key="loops">
            <TutorialPage title="Loops">
                <p>
                    Writing + over and over works well enough for small numbers,
                    but if you want to create larger numbers or add logic to
                    your code? That’s where looping comes in. A loop in
                    brainfuck is any block of code enclosed between a '[' and a
                    ']'. When the code reaches the start of a loop ('['), it
                    checks the value of the current cell. If it is 0, then the
                    code continues at the next instruction after the end of loop
                    (']'). Otherwise, the body of the loops starts executing.
                    Once the code reaches the end of the loop, it will go back
                    to the start and repeat.
                </p>
            </TutorialPage>

            <TutorialPage title="Exercise 1" isCompleted={completedSet[ex1]}>
                <p>
                    The current code uses a loop to produce the value 6. After
                    setting the first memory cell to 3, it loops over some code
                    that adds 2 to the second cell, decrements the first cell,
                    and repeats until the first cell reaches 0. Essentially, it
                    performs the computation 3 * 2.
                </p>
                <p>
                    Modify the code so that instead of producing 6, it produces
                    12 in the second cell.
                </p>

                <BrainfuckTutorialEditor
                    key={ex1}
                    problemId={ex1}
                    defaultCode="+++[>++<-]"
                    markComplete={markComplete}
                />
            </TutorialPage>

            <TutorialPage title="Exercise 2" isCompleted={completedSet[ex2]}>
                <p>
                    Use a loop to write the number 24 in the second memory cell.
                </p>

                <BrainfuckTutorialEditor
                    key={ex2}
                    problemId={ex2}
                    markComplete={markComplete}
                />
            </TutorialPage>

            <TutorialPage title="Exercise 3" isCompleted={completedSet[ex3]}>
                <p>
                    The current code introduces a second, inner loop to produce
                    the value 16 in the third cell. Similarly to the first
                    exercise, it performs the computation 4 * 2 * 2.
                </p>
                <p>Modify the code so that it produces 24 instead.</p>

                <BrainfuckTutorialEditor
                    key={ex3}
                    problemId={ex3}
                    defaultCode="++++[>++[>++<-]<-]"
                    markComplete={markComplete}
                />
            </TutorialPage>

            <TutorialPage title="Exercise 4" isCompleted={completedSet[ex4]}>
                <p>
                    Use nested loops to write the number 75 into the third cell.
                </p>

                <BrainfuckTutorialEditor
                    key={ex4}
                    problemId={ex4}
                    markComplete={markComplete}
                />
            </TutorialPage>
        </TutorialSection>
    );
}

export default BrainfuckTutorialLoops;
