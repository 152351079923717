const MEM_SIZE = 256; // original implementation is 30,000
const NUM_INTS = 256; // TODO: is this correct?
const EOF = 0;

// enum for error types
const ERROR_SYNTAX = 'syntax';
const ERROR_TIMEOUT = 'timeout';

const isCommand = (c) => '+-,.<>[]'.indexOf(c) >= 0;

/**
 * Creates a mapping of loop start/end positions the position of their matching pair
 * @param {string | [string]} code the code to find loop pairs in
 * @return {Object.<string, number>} the mapping created
 */
function findLoops(code) {
    let startStack = [],
        loopPairs = {};

    for (let i = 0; i < code.length; i++) {
        if (code[i] === '[') {
            startStack.push(i);
        } else if (code[i] === ']') {
            let startIndex = startStack.pop();
            loopPairs[i] = startIndex;
            loopPairs[startIndex] = i;
        }
    }

    return loopPairs;
}

/**
 * A single execution of a Brainfuck program. Contains the program state,
 * and can be used to step through the program one instruction at a time.
 */
class BrainfuckExecution {
    _reset() {
        this.mem = BrainfuckInterpreter.emptyMemory();
        this.pc = 0; // program counter
        this.dp = 0; // data pointer
        this.ip = 0; // input pointer
        this.output = ''; // STDOUT

        /**
         * contains an error if one has occurred, otherwise null
         * @name BrainfuckExecution#err
         * @type {?{type: string, message: string, position: number}}
         * @default null
         */
        this.err = null; // contains an error message, if an error has occurred
    }

    _checkSyntax() {
        let loopStack = [];
        for (let i = 0; i < this.code.length; i++) {
            let c = this.code.charAt(i);
            if (c === '[') {
                loopStack.push(i);
            } else if (c === ']') {
                if (loopStack.length === 0) {
                    this.err = {
                        type: ERROR_SYNTAX,
                        message: "Syntax Error: missing opening '[' for ']'",
                        position: i,
                    };
                    break;
                }
                loopStack.pop();
            }
        }

        if (loopStack.length !== 0) {
            this.err = {
                type: ERROR_SYNTAX,
                message: "Syntax Error: '[' missing closing ']'",
                position: loopStack.pop(),
            };
        }
    }

    /**
     *
     * @param {string} code the brainfuck code to execute
     * @param {string} input a string representing STDIN
     */
    constructor(code, input) {
        this.code = code;
        this.input = input;
        this.loopPairs = findLoops(code);
        this._reset();
        this._checkSyntax();
    }

    /**
     * Sets the memory tape to given values.
     * If the input is too short, anything past it is left unmodified.
     * If the input is too long, it is truncated to the memory tape size.
     * @param {number[]} inputMem
     */
    setMemory(inputMem) {
        let copySize = Math.min(inputMem.length, BrainfuckInterpreter.MEM_SIZE);
        for (let i = 0; i < copySize; i++) {
            this.mem[i] = inputMem[i];
        }
    }

    nextPc() {
        let pc = this.pc;
        while (pc < this.code.length && !isCommand(this.code[pc])) {
            pc++;
        }
        return pc;
    }

    /**
     * Steps through one instruction of the current program, skipping comments
     * @returns {boolean} true if it succeeds in stepping, false if program has completed
     */
    step() {
        this.pc = this.nextPc();

        if (this.hasCompleted()) {
            return false;
        }

        let cmd = this.code[this.pc];
        if (cmd === '>') {
            // TODO: memory overflow behavior (wrap? error? extend?)
            this.dp = (this.dp + 1) % MEM_SIZE;
        } else if (cmd === '<') {
            this.dp--;
            if (this.dp < 0) {
                this.dp += MEM_SIZE;
            }
        } else if (cmd === '+') {
            this.mem[this.dp] = (this.mem[this.dp] + 1) % NUM_INTS;
        } else if (cmd === '-') {
            this.mem[this.dp]--;
            if (this.mem[this.dp] < 0) {
                this.mem[this.dp] += NUM_INTS;
            }
        } else if (cmd === '.') {
            this.output += String.fromCharCode(this.mem[this.dp]);
        } else if (cmd === ',') {
            let c;
            if (this.ip >= this.input.length) {
                c = EOF;
            } else {
                c = this.input[this.ip++].charCodeAt(0);
            }
            this.mem[this.dp] = c % NUM_INTS; // TODO: is this correct way to handle large character codes?
        } else if (cmd === '[') {
            if (this.mem[this.dp] === 0) {
                this.pc = this.loopPairs[this.pc];
            }
        } else if (cmd === ']') {
            this.pc = this.loopPairs[this.pc] - 1;
        }

        this.pc++;
        return true;
    }

    runToCompletion() {
        while (!this.hasCompleted()) {
            this.step();
        }
    }

    /**
     * @returns {bool} true if the program has completed its execution or an error has occurred
     */
    hasCompleted() {
        return this.pc >= this.code.length || Boolean(this.err);
    }
}

/**
 * The interface for running or debugging brainfuck programs.
 */
class BrainfuckInterpreter {
    /** The number of cells in memory */
    static MEM_SIZE = MEM_SIZE;

    /** The number of possible integer values. All possible ints are [0, NUM_INTS) */
    static NUM_INTS = NUM_INTS;

    /** Represents the type of a syntax error */
    static ERROR_SYNTAX = ERROR_SYNTAX;

    /** Represents the type of a timeout error */
    static ERROR_TIMEOUT = ERROR_TIMEOUT;

    /**
     * Completely executes a given program on some input
     * @param {string} code the brainfuck code to run
     * @param {string} [input] a string representing STDIN
     * @param {number[]} [inputMem] a starting memory tape for the program.
     * If too short, zeroes are appended. If too long, truncated.
     * @returns {BrainfuckExecution} the state of the program after executing it
     */
    static run(code, input, inputMem) {
        input = input || '';
        let bf = new BrainfuckExecution(code, input);

        if (inputMem) {
            bf.setMemory(inputMem);
        }
        bf.runToCompletion();

        return bf;
    }

    /**
     * Executes a given program on some input until it either terminates or times out.
     * @param {string} code the brainfuck code to run
     * @param {string} [input] a string representing STDIN
     * @param {number[]} [inputMem] a starting memory tape for the program.
     * If too short, zeroes are appended. If too long, truncated.
     * @param {number} limit the maximum time to execute for, in milliseconds
     * @returns {BrainfuckExecution} the state of the program after executing it
     */
    static runWithTimeLimit(code, input, inputMem, limit) {
        input = input || '';
        let bf = new BrainfuckExecution(code, input);

        if (inputMem) {
            bf.setMemory(inputMem);
        }

        let start = new Date();

        while (true) {
            let now = new Date();
            if (now - start > limit) {
                bf.err = {
                    type: ERROR_TIMEOUT,
                    message: 'Maximum time limit exceeded',
                    position: 0,
                };
                break;
            }

            if (!bf.step()) break;
        }

        return bf;
    }

    /**
     * Initializes execution so client can step through at their own pace
     * @param {string} code the brainfuck code to run
     * @param {string} [input] a string representing STDIN
     * @param {number[]} [inputMem] a starting memory tape for the program.
     * If too short, zeroes are appended. If too long, truncated.
     * @returns {BrainfuckExecution} a BrainfuckExecution that can be used to step through program
     */
    static debug(code, input, inputMem) {
        input = input || '';

        let bf = new BrainfuckExecution(code, input);
        if (inputMem) {
            bf.setMemory(inputMem);
        }
        return bf;
    }

    /**
     * Creates a new array representing an empty memory tape.
     * @returns {[number]} an array of size MEM_SIZE with all zeroes
     */
    static emptyMemory() {
        return new Array(MEM_SIZE).fill(0);
    }

    /**
     * Takes a memory array and either truncates it if it is longer than MEM_SIZE or pads
     * it with 0s if it is shorter
     * @param {[number]} mem
     * @returns {[number]} an array of size MEM_SIZE containing the contents of mem
     */
    static sanitizeMemory(mem) {
        let res = BrainfuckInterpreter.emptyMemory(),
            copyLength = Math.min(res.length, mem.length);

        for (let i = 0; i < copyLength; i++) {
            res[i] = mem[i];
        }

        return res;
    }
}

export default BrainfuckInterpreter;
