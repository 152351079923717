import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Login from './auth/Login';
import setAuthToken from './auth/setAuthToken';
import jwt_decode from 'jwt-decode';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { Provider } from 'react-redux';
import store from './store';
import Register from './auth/Register';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Challenges from './challenges/Challenges';
import Sandbox from './pages/Sandbox';
import NotFound from './pages/NotFound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PrivateRoute from './auth/PrivateRoute';
import axios from 'axios';
import FeedbackPage from './pages/FeedbackPage';
import TutorialsPage from './tutorials/TutorialsPage';
import MiscellaneousPage from './pages/MiscellaneousPage';
import LocalStorage from './storage/LocalStorage';

// Set baseURL to request from the server API
axios.defaults.baseURL = '/api/v1';

// check for token to keep user logged in
const token = LocalStorage.getItem('jwtToken');
if (token) {
    // set auth token header auth
    setAuthToken(token);

    let decoded = null;
    try {
        decoded = jwt_decode(token);
    } catch (error) {
        // jwt_decode crashes if the jwt is malformed,
        // which in turn crashes the whole website if uncaught
        console.error(error?.message);
        store.dispatch(logoutUser());
    }

    if (decoded) {
        store.dispatch(setCurrentUser(decoded));

        // check for expired token
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            // log out and redirect to login
            store.dispatch(logoutUser());
            window.location.href = './login';
        }

        axios
            .post('/amILoggedIn')
            .then((res) => {
                const isLoggedIn = res.status === 200;
                if (!isLoggedIn) {
                    store.dispatch(logoutUser());
                }
            })
            .catch(() => store.dispatch(logoutUser()));
    }
}

function wrapApp(children) {
    return (
        <Provider store={store}>
            <HelmetProvider>{children}</HelmetProvider>
        </Provider>
    );
}

function App() {
    return wrapApp(
        <Router>
            <Helmet>
                <title>EsoLab</title>
            </Helmet>

            <Header />

            <div id="body">
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/challenges" component={Challenges} />
                    <PrivateRoute path="/tutorials" component={TutorialsPage} />
                    <Route path="/sandbox" component={Sandbox} />
                    <Route path="/misc" component={MiscellaneousPage} />

                    <Route path="/about" exact component={AboutPage} />
                    <Route path="/feedback" exact component={FeedbackPage} />
                    <Route
                        path="/feedback/bug"
                        exact
                        component={FeedbackPage.ReportBug}
                    />

                    <Route path="/login" exact component={Login} />
                    <Route path="/register" exact component={Register} />

                    <Route component={NotFound} />
                </Switch>
            </div>

            <Footer />
        </Router>
    );
}

export default App;
