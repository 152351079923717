import React from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import LocalStorage from '../../storage/LocalStorage';
import ChefMode from './ChefMode';
import cmdEnterToRun from '../cmdEnterToRun';
require('ace-builds/src-noconflict/theme-xcode');

class ChefIDE extends React.Component {
    constructor(props) {
        super(props);

        const { editorId } = props;

        this.editorId = editorId ? `chef-editor:${editorId}` : undefined;

        this.editorRef = React.createRef();

        let code = '';
        if (editorId) {
            code = LocalStorage.getItem(this.editorId) || code;
        }

        this.state = {
            code,
            activeIOTab: 'input',
            input: '',
            output: '',
            errorMessage: '',
            runningCode: false,
        };
    }

    componentDidMount() {
        const chefMode = new ChefMode();
        this.editorRef.current.editor.getSession().setMode(chefMode);
        this.editorRef.current.editor.commands.addCommand({
            exec: this.runCode,
            bindKey: { mac: 'Command-Enter', win: 'Ctrl-Enter' },
        });
    }

    render() {
        return (
            <div
                className="chef-ide"
                onKeyDown={(e) => cmdEnterToRun(e, this.runCode)}
            >
                <Row>
                    <AceEditor
                        ref={this.editorRef}
                        mode="text"
                        theme="xcode"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            tabSize: 2,
                            useSoftTabs: true,
                            showPrintMargin: false,
                            vScrollBarAlwaysVisible: false,
                            fontSize: 14,
                        }}
                        width="100%"
                        height="400px"
                        value={this.state.code}
                        wrapEnabled={true}
                        onChange={this.onChangeCode}
                    />
                </Row>

                <Row className="chef-bottom-buttons">
                    <Button
                        className="chef-run-button"
                        variant="primary"
                        onClick={this.runCode}
                        disabled={this.state.runningCode}
                    >
                        Run
                    </Button>
                </Row>

                <div className="chef-io-section">
                    <Tabs
                        activeKey={this.state.activeIOTab}
                        transition={false}
                        onSelect={this.onSelectIOTab}
                    >
                        <Tab eventKey="input" title="Input">
                            <br />
                            <textarea
                                className="chef-io-input"
                                value={this.state.input}
                                onChange={this.onChangeInput}
                            />
                        </Tab>
                        <Tab eventKey="output" title="Output">
                            <br />
                            <>
                                {this.state.errorMessage ? (
                                    <Alert
                                        className="chef-error-dialog"
                                        variant="danger"
                                    >
                                        {this.state.errorMessage
                                            .split('\n')
                                            .map((line, index) => (
                                                <div key={index}>{line}</div>
                                            ))}
                                    </Alert>
                                ) : (
                                    <>
                                        <pre className="chef-io-output">
                                            {this.state.output}
                                        </pre>
                                    </>
                                )}
                            </>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }

    onSelectIOTab = (key) => {
        this.setState({
            activeIOTab: key,
        });
    };

    onChangeCode = (code) => {
        this.setState({ code });

        if (this.editorId) {
            LocalStorage.setItem(this.editorId, code);
        }
    };

    onChangeInput = (e) => {
        this.setState({ input: e.target.value });
    };

    runCode = (e) => {
        this.setState({
            runningCode: true,
        });

        const { code, input } = this.state;

        axios
            .post('/run/chef', {
                code,
                input,
            })
            .then((res) => {
                let data = res.data;

                this.setState({
                    output: data.stdout,
                    errorMessage: data.stderr,
                    runningCode: false,
                    activeIOTab: 'output',
                });
            })
            .catch((err) => {
                console.log(err?.response);
                this.setState({
                    output: '',
                    errorMessage: 'A server error occurred',
                    runningCode: false,
                    activeIOTab: 'output',
                });
            });
    };
}

export default ChefIDE;
