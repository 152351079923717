import React from 'react';

import './tutorial.css';

function RightChevron() {
    // https://icons.getbootstrap.com/icons/chevron-right/
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
            style={{ marginTop: '-2px' }}
        >
            <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
        </svg>
    );
}

function LeftChevron() {
    // https://icons.getbootstrap.com/icons/chevron-left/
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
            style={{ marginTop: '-2px' }}
        >
            <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
        </svg>
    );
}

function CheckMark() {
    // https://icons.getbootstrap.com/icons/check/
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="currentColor"
            className="bi bi-check"
            viewBox="0 0 16 16"
            style={{ marginTop: '-2px' }}
        >
            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
        </svg>
    );
}

function TutorialPage(props) {
    let { title, section, subSection, onSelect, isCompleted } = props;

    let className = 'tutorial-index-item';
    if (props.selected) {
        className += ' tutorial-index-item-selected';
    }

    let indexLabel = `${section + 1}.`;
    if (subSection !== 0) {
        indexLabel += subSection;
    }
    indexLabel += ' ';

    return (
        <p className={className} onClick={() => onSelect(subSection)}>
            {indexLabel}
            {title} {isCompleted ? <CheckMark /> : <></>}
        </p>
    );
}

function TutorialSection(props) {
    let { section, selectedPage, onSelect } = props;

    let children = React.Children.map(props.children, (child, subSection) => {
        return React.cloneElement(child, {
            section,
            subSection,
            selected:
                selectedPage.section === section &&
                selectedPage.subSection === subSection,
            onSelect: (...args) => onSelect(section, ...args),
        });
    });

    if (children.length === 0) {
        throw new Error(
            'TutorialSection must contain at least one TutorialItem'
        );
    } else if (children.length === 1) {
        return <>{children}</>;
    }

    return (
        <>
            {children[0]}
            <div className="tutorial-index-subsection">{children.slice(1)}</div>
        </>
    );
}

class Tutorial extends React.Component {
    /**
     * Creates an object representing each tutorial item
     * by looking through this component's children and grandchildren
     * @param {any} children
     * @returns {[{section: number, subSection: number, title: string, content: any}]}
     */
    _enumerateItems = (children) => {
        children = React.Children.toArray(children);

        let section = 0,
            subSection = 0,
            allItems = [];

        for (let child of children) {
            let items = React.Children.toArray(child.props.children);

            for (let item of items) {
                allItems.push({
                    section,
                    subSection,
                    title: item.props.title,
                    content: item.props.children,
                });
                subSection++;
            }

            section++;
            subSection = 0;
        }

        return allItems;
    };

    constructor(props) {
        super(props);

        this.allItems = this._enumerateItems(props.children);

        this.state = {
            selectedIndex: 0,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this._resizeTutorial);
        this._resizeTutorial();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeTutorial);
    }

    _resizeTutorial() {
        const header = document.getElementById('header'),
            footer = document.getElementById('footer'),
            tutorials = document.getElementsByClassName('tutorial-container');

        for (let tutorial of tutorials) {
            const height =
                window.innerHeight - header.offsetHeight - footer.offsetHeight;
            tutorial.style.height = height + 'px';
        }
    }

    render() {
        let { selectedIndex } = this.state,
            selectedPage = this.allItems[selectedIndex],
            sections = this.props.children.map((child, index) =>
                React.cloneElement(child, {
                    section: index,
                    selectedPage,
                    onSelect: this.onSelectIndex,
                })
            );

        return (
            <div className="tutorial-container">
                <div className="tutorial-wrapper">
                    <div className="tutorial-content">
                        <div className="tutorial-index-col">{sections}</div>

                        <div className="tutorial-instructions-col">
                            <h1 className="tutorial-title">
                                {selectedPage.title}
                            </h1>

                            {selectedPage.content}

                            <div className="tutorial-page-controls">
                                <div
                                    className="tutorial-previous-button"
                                    hidden={selectedIndex === 0}
                                    onClick={this.onClickPrevious}
                                >
                                    <LeftChevron />
                                    Previous
                                </div>
                                <div
                                    className="tutorial-next-button"
                                    hidden={
                                        selectedIndex ===
                                        this.allItems.length - 1
                                    }
                                    onClick={this.onClickNext}
                                >
                                    Next
                                    <RightChevron />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _absoluteIndexFrom = (section, subSection) => {
        for (let i = 0; i < this.allItems.length; i++) {
            let item = this.allItems[i];
            if (item.section === section && item.subSection === subSection) {
                return i;
            }
        }

        // shouldn't reach here
        return 0;
    };

    onSelectIndex = (section, subSection) => {
        this.setState({
            selectedIndex: this._absoluteIndexFrom(section, subSection),
        });
    };

    onClickPrevious = () => {
        this.setState({
            selectedIndex: this.state.selectedIndex - 1,
        });
    };

    onClickNext = () => {
        this.setState({
            selectedIndex: this.state.selectedIndex + 1,
        });
    };
}

export { Tutorial, TutorialPage, TutorialSection };
