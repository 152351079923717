function HomePage() {
    return (
        <div className="page-body">
            <h1>Welcome to EsoLab!</h1>
            <p>
                EsoLab is the official website for the course{' '}
                <i>98-242 Introduction to Esoteric Programming Languages</i> at
                Carnegie Mellon University. The site provides a platform for
                users to learn and practice coding in esoteric programming
                languages through tutorials and coding challenges.
            </p>

            <p>
                Anyone is welcome to practice their coding skills on EsoLab,
                regardless of enrollment status at CMU. Sign up for a free
                account to get started!
            </p>

            <p>
                Students of 98-242 can access materials such as lecture
                recordings and slides on the course's{' '}
                <a
                    href="https://canvas.cmu.edu/courses/26685"
                    target="_blank"
                    rel="noreferrer"
                >
                    Canvas page
                </a>
                .
            </p>
        </div>
    );
}

export default HomePage;
