import { Helmet } from 'react-helmet-async';

function NotFound() {
    return (
        <div className="page-body text-center">
            <Helmet>
                <title>Page Not Found | EsoLab</title>
            </Helmet>

            <h1>Page Not Found</h1>
            <p>
                The rest of this page has been rendered as a whitespace program.
            </p>
        </div>
    );
}

export default NotFound;
