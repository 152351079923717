import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function PrivateRoute({ component, auth, altComponent, ...rest }) {
    const AltComponent = altComponent,
        Component = component;

    const render = (props) => {
        if (auth.isAuthenticated) {
            return <Component {...props} />;
        }
        if (AltComponent) {
            return <AltComponent />;
        }
        return <Redirect to="/login" />;
    };

    return <Route {...rest} render={render} />;
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
