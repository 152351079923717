import BrainfuckIDE from '../../interpreters/brainfuck/BrainfuckIDE';
import { TutorialSection, TutorialPage } from '../Tutorial';
import BrainfuckTutorialEditor from './BrainfuckTutorialEditor';

const ex1 = 'brainfuck/tutorial/input-exercise-1';

function BrainfuckTutorialInput(completedSet, markComplete) {
    return (
        <TutorialSection key="input">
            <TutorialPage title="Input">
                <p>
                    Taking in input is similar to writing output. The ','
                    instruction reads a single character from input and puts its
                    character code into the current cell. Each time you use ','
                    the next character is read until there are no characters
                    remaining. From then on, the next input will always be 0.
                </p>
            </TutorialPage>

            <TutorialPage title="Exercise 1" isCompleted={completedSet[ex1]}>
                <p>
                    Write a program that reads in 3 characters from input then
                    prints them in reverse order. You may assume the input will
                    be at least 3 characters long (if there are fewer than 3
                    characters, your program may behave however you want).
                </p>
                <p>
                    For example, if the input is "abc", your program should
                    print "cba".
                </p>

                <BrainfuckTutorialEditor
                    key={ex1}
                    problemId={ex1}
                    markComplete={markComplete}
                    defaultInputMode={BrainfuckIDE.IO_TEXT_MODE}
                />
            </TutorialPage>
        </TutorialSection>
    );
}

export default BrainfuckTutorialInput;
