import { Helmet } from 'react-helmet-async';
import EsoDeck from '../ui-components/cards/EsoDeck';
import { Route, Redirect, Switch } from 'react-router-dom';
import BrainfuckTutorial from './brainfuck/BrainfuckTutorial';
import BefungeTutorial from './befunge/BefungeTutorial';

function withRoutes(Component) {
    return () => (
        <Switch>
            <Route path="/tutorials" exact component={Component} />
            <Route path="/tutorials/befunge" component={BefungeTutorial} />
            <Route path="/tutorials/brainfuck" component={BrainfuckTutorial} />
            <Redirect to="/tutorials" />
        </Switch>
    );
}

function TutorialsPage() {
    return (
        <div className="page-body">
            <Helmet>
                <title>Tutorials | EsoLab</title>
            </Helmet>

            <h1>Tutorials</h1>

            <EsoDeck className="challenges-module-deck">
                <EsoDeck.Card name="Befunge" linkTo="/tutorials/befunge" />
                <EsoDeck.Card name="Brainfuck" linkTo="/tutorials/brainfuck" />
            </EsoDeck>
        </div>
    );
}

export default withRoutes(TutorialsPage);
