import './chef.css';

import { Helmet } from 'react-helmet-async';
import ChefIDE from './ChefIDE';

function ChefSandbox() {
    return (
        <div className="page-body intercal-sandbox-container">
            <Helmet>
                <title>Chef | EsoLab</title>
            </Helmet>

            <h1 className="text-center">Chef</h1>

            <ChefIDE editorId="sandbox" />

            <p className="intercal-sandbox-references">
                Interpreted using the following{' '}
                <a
                    href="https://github.com/joostrijneveld/Chef-Interpreter"
                    target="_blank"
                    rel="noreferrer"
                >
                    Chef Interpreter
                </a>
                .
                <br />
                Reference{' '}
                <a
                    href="https://www.dangermouse.net/esoteric/chef.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    manual
                </a>
                .
            </p>
        </div>
    );
}

export default ChefSandbox;
