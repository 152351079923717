/**
 * TODO:
 *  - fix exports
 *  - code parsing
 *    - rule validation
 */

/*
origin (0, 0) is *
each branch is AXIS_LENGTH (A) long
grid length = 2 * AXIS_LENGTH + 1
        (0, A)
           |
(-A, 0) ---*--- (A, 0)
           |
        (0, -A)
*/
// const AXIS_LENGTH = 50;
const TIME_STEP = 0.001;
const COLLISION_EPSILON = 0.01;

// enum for error types
const ERROR_SYNTAX = 'syntax';
// const ERROR_TIMEOUT = 'timeout';

// (x, y) : m
const SPACE_CONFIG_REGEX = /^\((\s*\d+\s*),(\s*\d+\s*)\)\s*:\s*(\d+)$/;
// (a, b, c, ...)x? -> i:o
// const COLLISION_RULE_REGEX = /^\(([\d,\s,]+)\)(\s*\d*)\s*->(\s.*):(.*)$/;

export function PointParticle(x, y, m) {
    this.x = x;
    this.y = y;
    this.mass = m;
    this.velocity = {
        x: 0,
        y: 0,
    };
    // this.acceleration = {
    //     x: 0,
    //     y: 0,
    // };

    this.stageNext = (x, y, vx, vy) => {
        x = +x.toFixed(5);
        y = +y.toFixed(5);
        vx = +vx.toFixed(5);
        vy = +vy.toFixed(5);
        this.next = { x, y, vx, vy };
    };

    this.applyNext = () => {
        if (!this.next) return;

        this.x = this.next.x;
        this.y = this.next.y;
        this.velocity.x = this.next.vx;
        this.velocity.y = this.next.vy;

        this.next = null;
    };

    /**
     *
     * @param {PointParticle} p
     * @returns {{x: number, y: number}} the acceleration vector for the force of gravity of p on this
     */
    this.accelerationFrom = (p) => {
        // this = p_I, p = p_i
        const diff = {
                x: p.x - this.x,
                y: p.y - this.y,
            },
            diffMagnitude = Math.sqrt(diff.x * diff.x + diff.y * diff.y),
            denom = Math.pow(diffMagnitude, 3);

        return {
            x: (diff.x * p.mass) / denom,
            y: (diff.y * p.mass) / denom,
        };
    };

    this.isColliding = (p) => {
        const dist = Math.sqrt(
            Math.pow(this.x - p.x, 2) + Math.pow(this.y - p.y, 2)
        );
        return dist <= COLLISION_EPSILON;
    };
}

// "There must be at least two masses, at most one can be zero, and no two rules may have the same set of masses"
export function CollisionRule(masses, x, i, o) {
    this.masses = masses.sort();
    this.x = x;
    this.i = i;
    this.o = o;

    this.isAdvanced = !!this.x;
}

// comment out to supress warnings
/*
function indexToCoord(r, c) {
    return {
        x: c - AXIS_LENGTH,
        y: AXIS_LENGTH - r,
    };
}

function coordToIndex(x, y) {
    return {
        row: AXIS_LENGTH - y,
        col: AXIS_LENGTH + x,
    };
}
*/

export class GravityExecution {
    _parse(code) {
        const lines = code.split('\n'),
            points = {};

        let pos = 0,
            i;

        for (i = 0; i < lines.length; i++) {
            const matches = lines[i].trim().match(SPACE_CONFIG_REGEX);
            if (matches) {
                const [, x, y, m] = matches,
                    coords = `${x};${y}`;
                // index = coordToIndex(x, y);
                // this.grid[index.row][index.col].mass = m;
                if (points[coords]) {
                    this.err = {
                        type: ERROR_SYNTAX,
                        message: 'Syntax Error: duplicate particle definition',
                        position: pos,
                    };
                    return;
                }
                points[coords] = new PointParticle(x, y, m);
            } else {
                if (lines[i].trim() === '') {
                    pos += lines[0].length;
                    break;
                }
                this.err = {
                    type: ERROR_SYNTAX,
                    message: 'Syntax Error: invalid space configuration rule',
                    position: pos,
                };
                return;
            }
            pos += lines[0].length;
        }

        for (; i < lines.length; i++) {
            if (lines[i].trim().length) break;
        }

        for (; i < lines.length; i++) {}
    }

    constructor(code, input) {
        /** @type {[PointParticle]} */
        this.points = [];
        /** @type {[CollisionRule]} */
        this.rules = [];

        this.input = input || '';
        this.ip = 0;
        this.output = '';
        this.err = null;
        this.halted = false;

        // initialize grid to all 1s
        // for (let i = 0; i <= 2 * AXIS_LENGTH; i++) {
        //     const row = [];
        //     for (let j = 0; j <= 2 * AXIS_LENGTH; j++) {
        //         const { x, y } = indexToCoord(i, j);
        //         row.push(new PointParticle(x, y, 1));
        //     }
        //     this.grid.push(row);
        // }

        // parse code
        // this._parse(code);

        // TODO: ensure that advanced collision rules come before basic
    }

    // TODO: return null to make no rule apply?
    pollInput = () => {
        if (this.ip >= this.input.length) return null;
        return this.input.charCodeAt(this.ip);
    };

    collisionRuleFor = (ps) => {
        ps.sort((a, b) => a.mass - b.mass);

        L: for (let rule of this.rules) {
            if (rule.masses.length !== ps.length) continue;
            for (let i = 0; i < rule.masses.length; i++) {
                if (ps[i].mass !== rule.masses[i]) continue L;
            }

            if (rule.isAdvanced) {
                if (rule.x === this.pollInput()) return rule;
            } else {
                return rule;
            }
        }

        return null;
    };

    step = () => {
        if (this.halted) return false;

        for (let p of this.points) {
            let ax = 0,
                ay = 0;
            for (let p2 of this.points) {
                if (p === p2) continue;
                const { x, y } = p.accelerationFrom(p2);
                ax += x;
                ay += y;
            }

            const vx = p.velocity.x + ax * TIME_STEP,
                vy = p.velocity.y + ay * TIME_STEP,
                x = p.x + p.velocity.x * TIME_STEP,
                y = p.y + p.velocity.y * TIME_STEP;
            p.stageNext(x, y, vx, vy);
        }

        this.points.forEach((p) => p.applyNext());

        let collisions = [];
        for (let i = 0; i < this.points.length; i++) {
            const p = this.points[i];
            for (let j = i + 1; j < this.points.length; j++) {
                const p2 = this.points[j];
                if (!p.isColliding(p2)) continue;

                let foundCollisionSet = false;
                for (let collision of collisions) {
                    if (collision.has(p) || collision.has(p2)) {
                        collision.add(p);
                        collision.add(p2);
                        foundCollisionSet = true;
                        break;
                    }
                }
                if (!foundCollisionSet) {
                    collisions.push(new Set([p, p2]));
                }
            }
        }
        collisions = collisions.sort((a, b) => {
            const p1 = a.values().next().value,
                p2 = b.values().next().value;

            if (p1.x === p2.x) {
                return p1.y - p2.y;
            }
            return p1.x - p2.x;
        });

        const removedParticles = new Set(),
            newParticles = [];

        for (let c of collisions) {
            for (let c2 of collisions) {
                if (c === c2) continue;
                if (c.size !== c2.size) continue;
                for (let x of c) {
                    if (c2.has(x)) {
                        console.log('dup in collision', c, c2);
                        this.halted = true;
                    }
                }
                for (let x of c2) {
                    if (c.has(x)) {
                        console.log('dup in collision', c, c2);
                        this.halted = true;
                    }
                }
            }
        }

        for (let collision of collisions) {
            const rule = this.collisionRuleFor([...collision]);
            let x = 0,
                y = 0,
                maxMass = -1;
            collision.forEach((p) => {
                x += p.x;
                y += p.y;
                maxMass = Math.max(maxMass, p.mass);
                removedParticles.add(p);
            });
            x /= collision.size;
            y /= collision.size;

            if (rule) {
                console.log(rule.masses);
                if (rule.o) {
                    this.output += String.fromCharCode(rule.o);
                }
                if (rule.i === '#') {
                    const inputMass = this.pollInput();
                    this.ip++;
                    if (inputMass === null) {
                        this.halted = true;
                        return true;
                    }
                    newParticles.push(new PointParticle(x, y, inputMass));
                } else if (rule.i !== null) {
                    newParticles.push(new PointParticle(x, y, rule.i));
                }
            } else {
                newParticles.push(new PointParticle(x, y, maxMass));
            }
        }

        this.points = this.points.filter((p) => !removedParticles.has(p));
        this.points.push(...newParticles);

        return true;
    };
}

// TODO: for testing
window.PointParticle = PointParticle;
window.CollisionRule = CollisionRule;
window.GravityExecution = GravityExecution;

// TODO: change
// export const t = {
//     GravityExecution: GravityExecution,
//     PointParticle: PointParticle,
//     CollisionRule: CollisionRule,
// };
