import './challenges.css';

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router-dom';
import ChallengeModule from './ChallengeModule';
import ChallengeProblem from './ChallengeProblem';
import PrivateRoute from '../auth/PrivateRoute';
import EsoDeck from '../ui-components/cards/EsoDeck';
import Scoreboard from './Scoreboard';
import { connect } from 'react-redux';
import { getModules } from '../actions/challengesActions';

function ScoreboardIcon() {
    // https://icons.getbootstrap.com/icons/bar-chart-fill/
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            className="bi bi-bar-chart-fill"
            viewBox="0 0 16 16"
            style={{ marginTop: '-2px' }}
        >
            <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
        </svg>
    );
}

function withRoutes(Component) {
    return () => (
        <Switch>
            <Route exact path="/challenges/scoreboard" component={Scoreboard} />

            <Route
                path="/challenges/:moduleId/:collectionId/:problemId"
                component={ChallengeProblem}
            />

            <Redirect
                from="/challenges/:moduleId/:collectionId/"
                to="/challenges/:moduleId"
            />

            <Route path="/challenges/:moduleId" component={ChallengeModule} />

            <PrivateRoute
                component={Component}
                altComponent={UnauthenticatedPage}
            />
        </Switch>
    );
}

class Challenges extends React.Component {
    componentDidMount() {
        this.props.getModules();
    }

    render() {
        return (
            <div className="page-body">
                <Helmet>
                    <title>Challenges | EsoLab</title>
                </Helmet>

                <table className="challenges-header-container">
                    <tbody>
                        <tr>
                            <td>
                                <h1>Challenges</h1>
                            </td>
                            <td className="challenges-header-scoreboard-cell">
                                <Link to="/challenges/scoreboard">
                                    Scoreboard <ScoreboardIcon />
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <EsoDeck className="challenges-module-deck">
                    {this.props.modules.map((mod, index) => {
                        const id = `/challenges/${mod.id}`;
                        return (
                            <EsoDeck.Card
                                key={index + 1}
                                name={mod.name}
                                linkTo={id}
                            />
                        );
                    })}
                </EsoDeck>
            </div>
        );
    }
}

function UnauthenticatedPage() {
    return (
        <div className="page-body">
            <Helmet>
                <title>Challenges | EsoLab</title>
            </Helmet>

            <h1>Challenges</h1>
            <p>
                Please <Link to="/login">log in</Link> to view and solve
                challenges.
            </p>
        </div>
    );
}

const mapStateToProps = (state) => ({
    modules: state.challenges.modules,
});

export default withRoutes(connect(mapStateToProps, { getModules })(Challenges));
