import 'ace-builds/src-noconflict/mode-text';

export class BefungeHighlightRules extends window.ace.acequire(
    'ace/mode/text_highlight_rules'
).TextHighlightRules {
    constructor() {
        super();
        this.$rules = {
            start: [
                {
                    token: 'arithmetic',
                    regex: /[+\-*/%!`]/,
                },
                {
                    token: 'pcDirection',
                    regex: /[><^v#]/,
                },
                {
                    token: 'pcConditional',
                    regex: /[_|]/,
                },
                {
                    token: 'halt',
                    regex: /[@]/,
                },
                {
                    token: 'stringMode',
                    regex: /["]/,
                },
                {
                    token: 'stack',
                    regex: /[:\\$0-9]/,
                },
                {
                    token: 'io',
                    regex: /[.,gp&~]/,
                },
            ],
        };
    }
}

export default class BefungeMode extends window.ace.acequire('ace/mode/text')
    .Mode {
    constructor() {
        super();
        this.HighlightRules = BefungeHighlightRules;
    }
}
