import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import { withRouter } from 'react-router';
import { logoutUser } from './actions/authActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function ProfilePicture(props) {
    // https://icons.getbootstrap.com/icons/person-circle/
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className={`${props.className} bi bi-person-circle`}
            viewBox="0 0 16 16"
        >
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path
                fillRule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
            />
        </svg>
    );
}

function Header(props) {
    const {
        auth,
        location: { pathname },
    } = props;

    const onClickLogout = () => {
        props.logoutUser(props.history);
    };

    let activeTab = pathname.substring(1);
    if (activeTab.indexOf('/') !== -1) {
        activeTab = activeTab.substring(0, activeTab.indexOf('/'));
    }

    return (
        <Navbar id="header" expand="sm" variant="dark">
            <Navbar.Brand as={Link} to="/">
                <Image className="header-logo" src="/esolab-logo-white.svg" />
                EsoLab
            </Navbar.Brand>

            <Nav activeKey={activeTab}>
                <Nav.Link as={Link} to="/" eventKey="">
                    Home
                </Nav.Link>
                <Nav.Link as={Link} to="/tutorials" eventKey="tutorials">
                    Tutorials
                </Nav.Link>
                <Nav.Link as={Link} to="/challenges" eventKey="challenges">
                    Challenges
                </Nav.Link>
                <Nav.Link as={Link} to="/sandbox" eventKey="sandbox">
                    Sandbox
                </Nav.Link>
                <Nav.Link as={Link} to="/misc" eventKey="misc">
                    Misc
                </Nav.Link>
            </Nav>

            <Nav className="ml-auto">
                {auth.isAuthenticated ? (
                    <NavDropdown
                        title={
                            <>
                                <ProfilePicture className="header-profile-icon" />
                                {auth.user.username}
                            </>
                        }
                        alignRight
                    >
                        {/* <NavDropdown.Item>Profile</NavDropdown.Item> */}
                        <NavDropdown.Item onClick={onClickLogout}>
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                ) : (
                    <>
                        <Nav.Link as={Link} to="/login">
                            Log In
                        </Nav.Link>
                        <Nav.Link as={Link} to="/register">
                            Sign Up
                        </Nav.Link>
                    </>
                )}
            </Nav>
        </Navbar>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Header));
