import './challenges.css';

import React from 'react';
import { Helmet } from 'react-helmet-async';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { selectModule } from '../actions/challengesActions';
import { clearErrors } from '../actions/errorActions';

const difficultyVariants = {
    easy: 'success',
    medium: 'warning',
    hard: 'danger',
};

const allCollection = {
    name: 'All',
    id: 'all',
    problems: [],
};

function Tag(props) {
    return <Badge className="challenges-problem-card-badge" {...props} />;
}

function Problem(props) {
    const { problem } = props;
    const href = problem.isTutorial
        ? `/tutorials/${problem.moduleId}`
        : `/challenges/${problem.moduleId}/${problem.collectionId}/${problem.problemId}`;

    const badges = [],
        addBadge = (variant, content) =>
            badges.push(
                <Tag key={badges.length} variant={variant}>
                    {content}
                </Tag>
            );
    if (problem.difficulty) {
        addBadge(difficultyVariants[problem.difficulty], problem.difficulty);
    }
    addBadge('info', problem.collectionId);

    let pointsDisplay = <></>;
    if (problem.points) {
        pointsDisplay = (
            <span className="challenges-problem-points">
                {problem.points} pts
            </span>
        );
    }

    return (
        <Card
            as={Link}
            className={classnames({
                'challenges-problem-card': true,
                'challenges-problem-card-solved': problem.solved,
            })}
            to={href}
        >
            <Card.Body>
                <h3>
                    {problem.name} {pointsDisplay}
                </h3>

                <div>{badges}</div>

                {problem.solved ? (
                    <div className="right-detail">
                        <div className="solved-badge">
                            <div className="solved-text">Solved</div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </Card.Body>
        </Card>
    );
}

function CollectionIndex(props) {
    const { collection, isSelected, onClick } = props;

    return (
        <p
            className={classnames({
                'challenges-module-collection-index': true,
                'challenges-module-collection-index-selected': isSelected,
            })}
            onClick={() => onClick(collection.id)}
        >
            {collection.name}
        </p>
    );
}

class ChallengeModule extends React.Component {
    constructor(props) {
        super(props);

        const difficulties = [
            { name: 'Easy', checked: false },
            { name: 'Medium', checked: false },
            { name: 'Hard', checked: false },
        ];

        this.state = {
            selectedCollection: 'all',
            difficulties,
        };
    }

    componentDidMount() {
        const {
            match: {
                params: { moduleId },
            },
        } = this.props;
        this.props.selectModule(moduleId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.errors.selectModule) {
            this.props.clearErrors();
            this.props.history.push('/challenges');
        }
    }

    render() {
        const { module } = this.props,
            { selectedCollection, difficulties } = this.state;

        let name = module?.name || '',
            collections = module?.collections || [allCollection];

        const selectedDifficulties = difficulties
                .filter((d) => d.checked)
                .map((d) => d.name.toLowerCase()),
            problems = collections
                .find((c) => c.id === selectedCollection)
                .problems.filter(
                    (p) =>
                        selectedDifficulties.length === 0 ||
                        selectedDifficulties.indexOf(p.difficulty) > -1
                );

        return (
            <div className="challenges-module-page page-body">
                <Helmet>
                    <title>{name} Challenges | EsoLab</title>
                </Helmet>

                <h1>{name}</h1>

                <div className="challenges-module-wrapper">
                    <div className="challenges-module-content">
                        <div className="challenges-module-index-col">
                            <div className="challenges-module-index">
                                <h5>Collection</h5>

                                {collections.map((collection, index) => (
                                    <CollectionIndex
                                        key={index + 1}
                                        collection={collection}
                                        onClick={this.onClickCollection}
                                        isSelected={
                                            selectedCollection === collection.id
                                        }
                                    />
                                ))}

                                <h5>Difficulty</h5>

                                <Form>
                                    {this.state.difficulties.map(
                                        (difficulty, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={`${difficulty.name}-checkbox`}
                                                label={difficulty.name}
                                                checked={difficulty.checked}
                                                onChange={this.onClickDifficulty(
                                                    index
                                                )}
                                            />
                                        )
                                    )}
                                </Form>
                            </div>
                        </div>

                        <div className="challenges-module-problems-col">
                            {problems.map((problem, index) => (
                                <Problem key={index} problem={problem} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClickCollection = (collectionId) => {
        this.setState({ selectedCollection: collectionId });
    };

    onClickDifficulty = (index) => (e) => {
        const difficulties = [...this.state.difficulties];
        difficulties[index].checked = e.target.checked;

        this.setState({
            difficulties,
        });
    };
}

const mapStateToProps = (state) => ({
    module: state.challenges.selectedModule,
    errors: state.errors,
});

export default connect(mapStateToProps, { selectModule, clearErrors })(
    ChallengeModule
);
