import BrainfuckTutorialEditor from './BrainfuckTutorialEditor';
import { TutorialSection, TutorialPage } from '../Tutorial';
import BrainfuckIDE from '../../interpreters/brainfuck/BrainfuckIDE';

const exampleCode =
    "+++++ +++++             initialize counter (cell #0) to 10\n[                       use loop to set 70/100/30/10\n    > +++++ ++              add  7 to cell #1\n    > +++++ +++++           add 10 to cell #2\n    > +++                   add  3 to cell #3\n    > +                     add  1 to cell #4\n<<<< -                  decrement counter (cell #0)\n]\n> ++ .                  print 'H'\n> + .                   print 'e'\n+++++ ++ .              print 'l'\n.                       print 'l'\n+++ .                   print 'o'\n> ++ .                  print ' '\n<< +++++ +++++ +++++ .  print 'W'\n> .                     print 'o'\n+++ .                   print 'r'\n----- - .               print 'l'\n----- --- .             print 'd'\n> + .                   print '!'\n> .                     print '\\n'\n";

function BrainfuckTutorialIntroduction() {
    return (
        <TutorialSection key="introduction">
            <TutorialPage title="Introduction">
                <p>
                    Brainfuck is a minimal programming language created by Urban
                    Muller in 1993. The language was designed to make it easy to
                    write as small of a compiler as possible. As a result, the
                    entire language consists of only 8 different instructions!
                </p>
                <p>
                    In brainfuck, all data is stored in a memory tape, where
                    each cell contains a non-negative integer. The tape is
                    essentially an array (or list) of numbers that can be read
                    and updated by the program. There is also a data pointer,
                    which points to the current cell that the program is
                    operating on. Each cell is initially set to 0, and the
                    pointer starts at the first cell.
                </p>
                <p>
                    Each of the 8 brainfuck instructions are a single character,
                    and they each perform a simple command. Here’s a summary of
                    what they all do (we’ll go into more detail later):
                </p>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>+</td>
                            <td>Increment</td>
                            <td>Adds 1 to the current cell</td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td>Decrement</td>
                            <td>Subtracts 1 from the current cell</td>
                        </tr>
                        <tr>
                            <td>&lt;</td>
                            <td>Move Left</td>
                            <td>Moves the data pointer one cell to the left</td>
                        </tr>
                        <tr>
                            <td>&gt;</td>
                            <td>Move Right</td>
                            <td>
                                Moves the data pointer one cell to the right
                            </td>
                        </tr>
                        <tr>
                            <td>[</td>
                            <td>Start Loop</td>
                            <td>
                                If the current cell is 0, skip to the end of the
                                loop. Otherwise, proceed to loop
                            </td>
                        </tr>
                        <tr>
                            <td>]</td>
                            <td>End Loop</td>
                            <td>Go back to the start of the loop</td>
                        </tr>
                        <tr>
                            <td>,</td>
                            <td>Input</td>
                            <td>
                                Read one character of input and store its
                                character code in the current cell
                            </td>
                        </tr>
                        <tr>
                            <td>.</td>
                            <td>Output</td>
                            <td>
                                Output the contents of the current cell as a
                                single character
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p>All other characters are treated as comments.</p>
                <p>
                    Try running the example code below! You can click the ‘Run’
                    button to execute the full program or ‘Debug’ to step
                    through the code yourself or watch an animated step-through.
                </p>

                <BrainfuckTutorialEditor
                    editorId="brainfuck-tutorial-instructions"
                    defaultCode={exampleCode}
                    defaultInputMode={BrainfuckIDE.IO_TEXT_MODE}
                />
            </TutorialPage>
        </TutorialSection>
    );
}

export default BrainfuckTutorialIntroduction;
