const ErrorCode = {
    SUCCESS: 0,
    COMPILATION: 1,
    RUNTIME: 2,
    TIMEOUT: 3,
    MEMORY: 4,
    WRONG_ANSWER: 5,
    SERVER: 6,
};

module.exports = ErrorCode;
