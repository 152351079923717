const Long = require('long');

const PLAYFIELD_WIDTH = 80;
const PLAYFIELD_HEIGHT = 25;

// enum for error types
const ERROR_SYNTAX = 'syntax';
const ERROR_TIMEOUT = 'timeout';
const ERROR_ARITHMETIC = 'arithmetic';

const DIR_LEFT = { x: -1, y: 0 };
const DIR_RIGHT = { x: 1, y: 0 };
const DIR_UP = { x: 0, y: -1 };
const DIR_DOWN = { x: 0, y: 1 };

const isDigit = (c) => /\d/.test(c);
const isWhitespace = (c) => /\s/.test(c);

class BefungeStack {
    constructor() {
        this._stack = [];
    }

    /**
     * Push a long integer value to the stack
     * @param {Long} x
     */
    push(x) {
        this._stack.push(x);
    }

    /**
     * Push an integer value to the stack
     * @param {number} x
     */
    pushInt(x) {
        this._stack.push(Long.fromInt(x));
    }

    /**
     * Push a boolean value to the stack as 1 (true) or 0 (false)
     * @param {boolean} b
     */
    pushBool(b) {
        if (b) {
            this._stack.push(Long.ONE);
        } else {
            this._stack.push(Long.ZERO);
        }
    }

    /**
     * Pop the top element from the stack
     * @returns {Long} the top element of the stack, or Long.ZERO if stack is empty
     */
    pop() {
        if (this._stack.length) {
            return this._stack.pop();
        }
        return Long.ZERO;
    }

    /**
     * Pop the top element from the stack
     * @returns {boolean} the top element of the stack as a boolean (0 -> false, else true). If stack is empty, false is returned.
     */
    popBool() {
        const b = this.pop();
        return !b.isZero();
    }

    size() {
        return this._stack.length;
    }

    toList() {
        return this._stack.map((x) => x.toInt());
    }
}

/**
 * A single execution of a Befunge program. Contains the program state,
 * and can be used to step through the program one instruction at a time.
 */
class BefungeExecution {
    // 80x25 grid of unsigned bytes
    // stack of signed long ints

    // pc: {x, y}, wraps around
    // dir: {x, y} (-1, 0, or 1)
    //   (0, 0) is top left, inc x right, inc y down
    // grid: byte[25][80]
    // stack
    // string mode: bool

    // EOF is 0

    _reset() {
        this.grid = [];
        for (let i = 0; i < PLAYFIELD_HEIGHT; i++) {
            this.grid[i] = new Array(PLAYFIELD_WIDTH).fill(32); // space char
        }

        this.pc = { x: 0, y: 0 }; // program counter
        this.dir = { x: 1, y: 0 }; // direction pointer
        this.stack = new BefungeStack();
        this.stringMode = false;
        this.terminated = false;
        this.ip = 0; // input pointer
        this.output = ''; // STDOUT

        /**
         * Contains an error if one has occurred, otherwise null
         * @name BefungeExecution#err
         * @type {?{type: string, message: string, position: {x: number, y: number}?}}
         * @default null
         */
        this.err = null;
    }

    _checkSyntax() {
        const rows = this.code.split('\n');
        if (rows.length > PLAYFIELD_HEIGHT) {
            this.err = {
                type: ERROR_SYNTAX,
                message: `Syntax Error: too many rows (${rows.length}). Maximum rows is ${PLAYFIELD_HEIGHT}`,
                position: null,
            };
            return;
        }

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].length > PLAYFIELD_WIDTH) {
                this.err = {
                    type: ERROR_SYNTAX,
                    message: `Syntax Error: too many columns on row ${i} (${rows[i].length}). Maximum column width is ${PLAYFIELD_WIDTH}`,
                    position: null,
                };
                return;
            }
        }
    }

    _setCode() {
        let rows = this.code.split('\n');
        for (let i = 0; i < Math.min(rows.length, PLAYFIELD_HEIGHT); i++) {
            for (
                let j = 0;
                j < Math.min(rows[i].length, PLAYFIELD_WIDTH);
                j++
            ) {
                this.grid[i][j] = rows[i].charCodeAt(j);
            }
        }
    }

    /**
     *
     * @param {string} code the befunge code to execute
     * @param {string} input a string representing STDIN
     */
    constructor(code, input) {
        this.code = code;
        this.input = input;
        this._reset();
        this._checkSyntax();
        this._setCode();
    }

    nextPc() {
        let pc = this.pc,
            dir = this.dir,
            x = (pc.x + dir.x) % PLAYFIELD_WIDTH,
            y = (pc.y + dir.y) % PLAYFIELD_HEIGHT;

        if (x < 0) {
            x += PLAYFIELD_WIDTH;
        }
        if (y < 0) {
            y += PLAYFIELD_HEIGHT;
        }

        return { x, y };
    }

    /**
     * Steps through one instruction of the current program, skipping comments
     * @returns {boolean} true if it succeeds in stepping, false if program has completed
     */
    step() {
        if (this.hasCompleted()) {
            return false;
        }

        const cmd = String.fromCharCode(this.grid[this.pc.y][this.pc.x]),
            stack = this.stack,
            pc = this.pc;

        if (cmd === '"') {
            this.stringMode = !this.stringMode;
        } else if (this.stringMode) {
            stack.pushInt(cmd.charCodeAt(0));
        } else if (cmd === '+') {
            const y = stack.pop(),
                x = stack.pop();
            stack.push(x.add(y));
        } else if (cmd === '-') {
            const y = stack.pop(),
                x = stack.pop();
            stack.push(x.sub(y));
        } else if (cmd === '*') {
            const y = stack.pop(),
                x = stack.pop();
            stack.push(x.mul(y));
        } else if (cmd === '/') {
            const y = stack.pop(),
                x = stack.pop();
            if (y.isZero()) {
                this.err = {
                    type: ERROR_ARITHMETIC,
                    message: 'Arithmetic Error: attempt to divide by zero',
                    position: {
                        ...pc,
                    },
                };
            } else {
                stack.push(x.div(y));
            }
        } else if (cmd === '%') {
            const y = stack.pop(),
                x = stack.pop();
            if (y.isZero()) {
                this.err = {
                    type: ERROR_ARITHMETIC,
                    message: 'Arithmetic Error: attempt to divide by zero',
                    position: {
                        ...pc,
                    },
                };
            } else {
                // mod uses sign of second argument
                // based on reference interpreter written in C
                // stack.push(Math.sign(y) * Math.abs(x % y));
                stack.push(x.mod(y));
            }
        } else if (cmd === '!') {
            const x = stack.pop();
            stack.pushBool(x.isZero());
        } else if (cmd === '`') {
            const y = stack.pop(),
                x = stack.pop();
            stack.pushBool(x.greaterThan(y));
        } else if (cmd === '>') {
            this.dir = DIR_RIGHT;
        } else if (cmd === '<') {
            this.dir = DIR_LEFT;
        } else if (cmd === '^') {
            this.dir = DIR_UP;
        } else if (cmd === 'v') {
            this.dir = DIR_DOWN;
        } else if (cmd === '?') {
            const rand = Math.floor(Math.random() * 4),
                dirs = [DIR_LEFT, DIR_RIGHT, DIR_UP, DIR_DOWN];
            this.dir = dirs[rand];
        } else if (cmd === '_') {
            const b = stack.popBool();
            if (b) {
                this.dir = DIR_LEFT;
            } else {
                this.dir = DIR_RIGHT;
            }
        } else if (cmd === '|') {
            const b = stack.popBool();
            if (b) {
                this.dir = DIR_UP;
            } else {
                this.dir = DIR_DOWN;
            }
        } else if (cmd === ':') {
            const x = stack.pop();
            stack.push(x);
            stack.push(x);
        } else if (cmd === '\\') {
            const y = stack.pop(),
                x = stack.pop();
            stack.push(y);
            stack.push(x);
        } else if (cmd === '$') {
            stack.pop();
        } else if (cmd === '.') {
            const x = stack.pop();
            // add space to match reference interpreter
            // TODO: keep this?
            this.output += x.toString() + ' ';
        } else if (cmd === ',') {
            const x = stack.pop();
            let charCode = x.mod(256).toInt();
            if (charCode < 0) {
                charCode += 256;
            }
            this.output += String.fromCharCode(charCode);
        } else if (cmd === '#') {
            this.pc = this.nextPc();
        } else if (cmd === 'g') {
            const y = stack.pop(),
                x = stack.pop();
            if (
                x < 0 ||
                x >= PLAYFIELD_WIDTH ||
                y < 0 ||
                y >= PLAYFIELD_HEIGHT
            ) {
                // out of bounds
                stack.push(Long.ZERO);
            } else {
                stack.pushInt(this.grid[y][x]);
            }
        } else if (cmd === 'p') {
            const y = stack.pop(),
                x = stack.pop(),
                v = stack.pop();
            if (
                x >= 0 &&
                x < PLAYFIELD_WIDTH &&
                y >= 0 &&
                y < PLAYFIELD_HEIGHT
            ) {
                let value = v.mod(256).toInt();
                if (value < 0) {
                    value += 256;
                }
                this.grid[y][x] = value;
            }
        } else if (cmd === '&') {
            let num = '',
                whiteSpacePrefix = 0;

            // find how much whitespace is at the front
            while (this.ip < this.input.length) {
                if (isWhitespace(this.input[this.ip + whiteSpacePrefix])) {
                    whiteSpacePrefix++;
                } else {
                    break;
                }
            }

            // check if negative
            if (
                this.ip + whiteSpacePrefix < this.input.length - 1 &&
                this.input.charAt(this.ip + whiteSpacePrefix) === '-' &&
                isDigit(this.input[this.ip + whiteSpacePrefix + 1])
            ) {
                num = '-';
                this.ip += whiteSpacePrefix + 1;
            }
            // check if is a number
            else if (
                this.ip + whiteSpacePrefix < this.input.length &&
                isDigit(this.input[this.ip + whiteSpacePrefix])
            ) {
                this.ip += whiteSpacePrefix;
            }

            // read as many consecutive digits as we can
            while (this.ip < this.input.length) {
                let c = this.input.charAt(this.ip);

                // check if digit
                if (isDigit(c)) {
                    num += c;
                } else {
                    break;
                }
                this.ip++;
            }

            if (num === '' || num === '-') {
                stack.push(Long.ZERO);
            } else {
                stack.push(Long.fromString(num));
            }
        } else if (cmd === '~') {
            if (this.ip < this.input.length) {
                stack.pushInt(this.input.charCodeAt(this.ip));
                this.ip++;
            } else {
                stack.pushInt(Long.ZERO);
            }
        } else if (cmd === '@') {
            this.terminated = true;
        } else if (isDigit(cmd)) {
            stack.pushInt(Long.fromString(cmd));
        }

        this.pc = this.nextPc();
        return true;
    }

    runToCompletion() {
        while (!this.hasCompleted()) {
            this.step();
        }
    }

    /**
     * @returns {bool} true if the program has completed its execution or an error has occurred
     */
    hasCompleted() {
        return this.terminated || this.err !== null;
    }

    /**
     * @returns {[number]} an array representing the internal stack
     */
    getStack() {
        return this.stack.toList();
    }

    /**
     * @returns {string} the playfield in string form (each row is a line of text)
     */
    getPlayfieldAsString() {
        return this.grid
            .map((xs) =>
                xs.map((charCode) => String.fromCharCode(charCode)).join('')
            )
            .join('\n');
    }
}

/**
 * The interface for running or debugging befunge programs.
 */
class BefungeInterpreter {
    /** width of the playfield */
    static PLAYFIELD_WIDTH = PLAYFIELD_WIDTH;

    /** height of the playfield */
    static PLAYFIELD_HEIGHT = PLAYFIELD_HEIGHT;

    /** Represents the type of a syntax error */
    static ERROR_SYNTAX = ERROR_SYNTAX;

    /** Represents the type of a timeout error */
    static ERROR_TIMEOUT = ERROR_TIMEOUT;

    /** Represents the type of an arithmetic error */
    static ERROR_ARITHMETIC = ERROR_ARITHMETIC;

    /**
     * Completely executes a given program on some input
     * @param {string} code the befunge code to run
     * @param {string} [input] a string representing STDIN
     * @returns {BefungeExecution} the state of the program after executing it
     */
    static run(code, input) {
        input = input || '';

        let bf = new BefungeExecution(code, input);
        bf.runToCompletion();
        return bf;
    }

    /**
     * Executes a given program on some input until it either terminates or times out.
     * @param {string} code the befunge code to run
     * @param {string} input a string representing STDIN
     * @param {number} limit the maximum time to execute for, in milliseconds
     * @returns {BefungeExecution} the state of the program after executing it
     */
    static runWithTimeLimit(code, input, limit) {
        input = input || '';
        let bf = new BefungeExecution(code, input);

        let start = new Date();

        while (true) {
            let now = new Date();
            if (now - start > limit) {
                bf.err = {
                    type: ERROR_TIMEOUT,
                    message: 'Maximum time limit exceeded',
                    position: null,
                };
                break;
            }

            if (!bf.step()) break;
        }

        return bf;
    }

    /**
     * Initializes execution so client can step through at their own pace
     * @param {string} code the befunge code to run
     * @param {string} [input] a string representing STDIN
     * @returns {BefungeExecution} a BefungeExecution that can be used to step through program
     */
    static debug(code, input) {
        input = input || '';

        let bf = new BefungeExecution(code, input);
        return bf;
    }
}

export default BefungeInterpreter;
