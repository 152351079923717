import { combineReducers } from 'redux';
import authReducer from './authReducer';
import challengesReducer from './challengesReducer';
import errorReducer from './errorReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    challenges: challengesReducer,
    errors: errorReducer,
});

export default rootReducer;
