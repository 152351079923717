import 'ace-builds/src-noconflict/mode-text';

export class BrainfuckHighlightRules extends window.ace.acequire(
    'ace/mode/text_highlight_rules'
).TextHighlightRules {
    constructor() {
        super();
        this.$rules = {
            start: [
                {
                    token: 'comment',
                    regex: /[^+\-,.<>[\]]+/,
                },
                {
                    token: 'arithmetic',
                    regex: /[+-]/,
                },
                {
                    token: 'io',
                    regex: /[.,]/,
                },
                {
                    token: 'shift',
                    regex: /[<>]/,
                },
                {
                    token: 'loop',
                    regex: /[[\]]/,
                },
            ],
        };
    }
}

export default class BrainfuckMode extends window.ace.acequire('ace/mode/text')
    .Mode {
    constructor() {
        super();
        this.HighlightRules = BrainfuckHighlightRules;
    }
}
