import React from 'react';
import BrainfuckTutorialEditor from './BrainfuckTutorialEditor';
import { TutorialSection, TutorialPage } from '../Tutorial';

const ex1 = 'brainfuck/tutorial/arithmetic-exercise-1';
const ex2 = 'brainfuck/tutorial/arithmetic-exercise-2';

function BrainfuckTutorialArithmetic(completedSet, markComplete) {
    return (
        <TutorialSection key="arithmetic">
            <TutorialPage title="Arithmetic">
                <p>
                    The fundamental building blocks of any brainfuck program are
                    the arithmetic and data pointer instructions. As a recap:
                    &lt;and &gt; move the data pointer left and right
                    respectively, + adds 1 to the current cell at the data
                    pointer, and - subtracts 1 from the current cell.
                </p>

                <p>
                    In this implementation of brainfuck, the memory tape
                    consists of exactly 256 cells, each of which stores a single
                    byte. This means that each cell can only contain values
                    0-255 inclusive. The tape wraps around, so moving left from
                    the first cell goes to the last cell and moving right from
                    the last cell returns to the first cell. Similarly, the
                    values in the cells wrap around as well so that they are
                    always between 0 and 255.
                </p>

                <p>
                    Try playing around with these in the interpreter. Debug mode
                    should be particularly helpful, as it lets you step through
                    the instructions one at a time and see the effects they have
                    on the memory tape.
                </p>

                <BrainfuckTutorialEditor editorId="brainfuck-tutorial-arithmetic" />
            </TutorialPage>

            <TutorialPage title="Exercise 1" isCompleted={completedSet[ex1]}>
                <p>
                    Modify the code below so that when run, the resulting memory
                    tape looks like | 3 | 2 | 1 | 0 ... 0 |
                </p>
                <p>
                    In other words, after the program runs the first 3 memory
                    cells should be 3, 2, and 1 respectively followed by all
                    zeroes.
                </p>

                <BrainfuckTutorialEditor
                    key={ex1}
                    problemId={ex1}
                    defaultCode="++ > ++"
                    markComplete={markComplete}
                />
            </TutorialPage>

            <TutorialPage title="Exercise 2" isCompleted={completedSet[ex2]}>
                <p>
                    Write code that will make the resulting memory tape look
                    like | 1 | 2 | 3 | 4 | 5 | 0 ... 0 |
                    <br />
                    (In other words, after the program runs the first 5 memory
                    cells should be 1, 2, 3, 4, and 5 respectively followed by
                    all zeroes.)
                </p>
                <p>
                    The "input" to the program is the following starting memory:
                    | 5 | 1 | 3 | 0 | 2 |
                    <br />
                    (the first 5 memory cells will be set to these values before
                    your program runs)
                </p>

                <BrainfuckTutorialEditor
                    key={ex2}
                    problemId={ex2}
                    markComplete={markComplete}
                    defaultInputMem={[5, 1, 3, 0, 2]}
                />
            </TutorialPage>
        </TutorialSection>
    );
}

export default BrainfuckTutorialArithmetic;
