import axios from 'axios';
import setAuthToken from '../auth/setAuthToken';
import { SET_CURRENT_USER, USER_LOADING } from './types';
import jwt_decode from 'jwt-decode';
import { setErrors } from './errorActions';
import LocalStorage from '../storage/LocalStorage';

export const registerUser = (userData, history) => (dispatch) => {
    axios
        .post('/users/register', userData)
        .then((res) => {
            const { token } = res.data;
            LocalStorage.setItem('jwtToken', token);
            setAuthToken(token);

            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            dispatch(setUserLoading(false));

            // TODO: go to some welcome page?
        })
        .catch((error) => dispatch(setErrors(error.response?.data || {})));
};

export const loginUser = (userData) => (dispatch) => {
    axios
        .post('/users/login', userData)
        .then((res) => {
            const { token } = res.data;
            LocalStorage.setItem('jwtToken', token);
            setAuthToken(token);

            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
            dispatch(setUserLoading(false));
        })
        .catch((error) => {
            dispatch(setErrors(error.response.data));
            dispatch(setUserLoading(false));
        });
};

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const setUserLoading = (isLoading) => {
    return {
        type: USER_LOADING,
        payload: isLoading,
    };
};

export const logoutUser = (history) => (dispatch) => {
    LocalStorage.clear();
    setAuthToken(null);
    dispatch(setCurrentUser(null));

    if (history) {
        history.push('/login');
    } else {
        window.location.href = '/login';
    }
};
