import React from 'react';
import { Helmet } from 'react-helmet-async';
import BrainfuckIDE from '../interpreters/brainfuck/BrainfuckIDE';
import BefungeIDE from '../interpreters/befunge/BefungeIDE';
import { connect } from 'react-redux';
import { selectProblem } from '../actions/challengesActions';
import { clearErrors } from '../actions/errorActions';

const nullProblem = { name: '', statement: [] };

class ChallengeProblem extends React.Component {
    componentDidMount() {
        const {
            match: {
                params: { moduleId, collectionId, problemId },
            },
        } = this.props;

        this.props.selectProblem(moduleId, collectionId, problemId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.errors.selectProblem) {
            const { moduleId } = this.props.match.params;
            this.props.clearErrors();
            this.props.history.push(`/challenges/${moduleId}`);
        }
    }

    render() {
        const problem = this.props.problem || nullProblem,
            { name, language, statement } = problem;

        return (
            <div className="challenges-problem-page page-body">
                <Helmet>
                    <title>{name} Challenge | EsoLab</title>
                </Helmet>

                <h1>{name}</h1>

                <div style={{ whiteSpace: 'pre-line' }}>
                    {statement?.map((txt, index) => (
                        <p
                            key={index}
                            dangerouslySetInnerHTML={{ __html: txt }}
                        />
                    ))}
                </div>

                <div className="challenges-problem-page-editor">
                    {this.editorForLanguage(language)}
                </div>
            </div>
        );
    }

    editorForLanguage = (language) => {
        if (!language) {
            return <></>;
        }

        const problem = this.props.problem,
            id = `${problem.moduleId}/${problem.collectionId}/${problem.problemId}`;

        if (language === 'befunge') {
            return <BefungeIDE editorId={id} submissionId={id} />;
        } else if (language === 'brainfuck') {
            let inputMode = BrainfuckIDE.IO_TEXT_MODE;
            if (
                problem.sampleTests &&
                problem.sampleTests.length > 0 &&
                problem.sampleTests[0].inputMem
            ) {
                inputMode = BrainfuckIDE.IO_TAPE_MODE;
            }
            return (
                <BrainfuckIDE
                    editorId={id}
                    submissionId={id}
                    defaultInputMode={inputMode}
                />
            );
        }

        return <></>;
    };
}

const mapStateToProps = (state) => ({
    problem: state.challenges.selectedProblem,
    errors: state.errors,
});

export default connect(mapStateToProps, { selectProblem, clearErrors })(
    ChallengeProblem
);
