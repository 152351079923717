import React from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';
import BrainfuckMode from './BrainfuckMode';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ToggleSwitch from './ToggleSwitch';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import BrainfuckMemoryTape from './BrainfuckMemoryTape';

import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-github';

import './brainfuck-theme.css';
import './brainfuck-ide.css';
import BrainfuckSubmissionResults from './BrainfuckSubmissionResults';
import LocalStorage from '../../storage/LocalStorage';

import BrainfuckInterpreter from './BrainfuckInterpreter';
import cmdEnterToRun from '../cmdEnterToRun';

// enum for IO modes
const IO_TEXT_MODE = 1;
const IO_TAPE_MODE = 2;
const ioModes = [
    { name: 'Text Mode', value: IO_TEXT_MODE },
    { name: 'Tape Mode', value: IO_TAPE_MODE },
];

// enum for debugger states
const DEBUG_UNSTARTED = 1;
const DEBUG_STEPPING = 2;
const DEBUG_PAUSED = 3;

const MIN_DELAY = 30;
const MAX_DELAY = 1000;
const DEFAULT_DELAY = 500; // milliseconds

const TIMEOUT_TIME = 5000;

/**
 * determines the line and column number for a given index (pos) in some code
 * @param {string} code a string representing some code
 * @param {number} pos an index of the code string
 * @returns {{line: number, col: number}} the (1-indexed) line and column number corresponding to the position in the code
 */
function getCodeLocation(code, pos) {
    let line = 1,
        col = 1;

    for (let i = 0; i < pos; i++) {
        if (code.charAt(i) === '\n') {
            line++;
            col = 1;
        } else {
            col++;
        }
    }

    return { line, col };
}

class BrainfuckIDE extends React.Component {
    static IO_TEXT_MODE = IO_TEXT_MODE;
    static IO_TAPE_MODE = IO_TAPE_MODE;

    _continueButtonString = () => {
        if (this.state.debugState === DEBUG_UNSTARTED) {
            return 'Start';
        } else if (this.state.debugState === DEBUG_STEPPING) {
            return 'Pause';
        } else {
            return 'Continue';
        }
    };

    getEditorId = () => {
        return `brainfuck-editor:${this.props.editorId}`;
    };

    constructor(props) {
        super(props);

        const {
            initState,
            editorId,
            defaultCode,
            defaultInputMode,
            defaultInput,
            defaultInputMem,
        } = props;

        this.editorRef = React.createRef();

        if (initState) {
            this.state = { ...initState };
        } else {
            let code = defaultCode || '';
            if (editorId) {
                code = LocalStorage.getItem(this.getEditorId()) || code;
            }

            this.state = {
                code,
                inputMode: defaultInputMode || IO_TEXT_MODE,
                activeIOTab: 'input',
                inputTapeMem:
                    defaultInputMem || BrainfuckInterpreter.emptyMemory(),
                input: defaultInput || '',
                output: '',
                outputTapeMem: BrainfuckInterpreter.emptyMemory(),
                errorMessage: null,
                debugMode: false,
                currentExecution: null,
                debugState: DEBUG_UNSTARTED,
                stepDelay: DEFAULT_DELAY,
                submissionResults: null,
            };
        }

        this._animateStepping = this._animateStepping.bind(this);
    }

    componentDidMount() {
        const brainfuckMode = new BrainfuckMode();
        this.editorRef.current.editor.getSession().setMode(brainfuckMode);
        this.editorRef.current.editor.commands.addCommand({
            exec: this.runCode,
            bindKey: { mac: 'Command-Enter', win: 'Ctrl-Enter' },
        });
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        // issue #61: fixes bug where editor doesn't update properly when switching between challenges
        // TODO: look into better solutions, e.g. using redux
        if (prevProps.editorId !== this.props.editorId) {
            if (this.props.editorId) {
                const code = LocalStorage.getItem(this.getEditorId()) || '';
                this.setState({ code });
            }
        }
    }

    render() {
        // if debugging, highlight next instruction
        let markers = [];
        if (this.editorRef?.current && this.state.debugMode) {
            let editor = this.editorRef.current.editor,
                code = editor.getSession().getValue(),
                loc = getCodeLocation(
                    code,
                    this.state.currentExecution.nextPc()
                );
            markers.push({
                startRow: loc.line - 1,
                startCol: loc.col - 1,
                endRow: loc.line - 1,
                endCol: loc.col,
                className: 'brainfuck-current-character',
                type: 'text',
            });
        }

        return (
            <>
                {this.state.debugMode ? (
                    <Row>
                        <BrainfuckMemoryTape
                            mem={this.state.currentExecution?.mem}
                            selectedIndex={this.state.currentExecution?.dp}
                        />
                    </Row>
                ) : (
                    <></>
                )}
                <Row className="brainfuck-top-buttons">
                    {this.state.debugMode ? (
                        <>
                            <div>
                                <Button
                                    variant="primary"
                                    className="brainfuck-ide-button"
                                    disabled={
                                        this.state.debugMode &&
                                        this.state.currentExecution?.hasCompleted()
                                    }
                                    onClick={this.debugContinue}
                                >
                                    {this._continueButtonString()}
                                </Button>

                                <Button
                                    variant="success"
                                    className="brainfuck-ide-button"
                                    disabled={
                                        this.state.debugMode &&
                                        (this.state.currentExecution?.hasCompleted() ||
                                            this.state.debugState ===
                                                DEBUG_STEPPING)
                                    }
                                    onClick={this.debugStep}
                                >
                                    Step
                                </Button>

                                <Button
                                    variant="danger"
                                    className="brainfuck-ide-button"
                                    onClick={this.debugStop}
                                >
                                    Stop
                                </Button>
                            </div>

                            <div className="brainfuck-delay-slider">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>Delay</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type="range"
                                                    min={MIN_DELAY}
                                                    max={MAX_DELAY}
                                                    value={this.state.stepDelay}
                                                    onInput={(e) =>
                                                        this.setState({
                                                            stepDelay:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="primary"
                                className="brainfuck-ide-button"
                                onClick={this.runCode}
                            >
                                Run
                            </Button>
                            <Button
                                variant="success"
                                className="brainfuck-ide-button"
                                onClick={this.debugCode}
                            >
                                Debug
                            </Button>
                            <ToggleSwitch
                                className="io-mode-button-group"
                                modes={ioModes}
                                selectedMode={this.state.inputMode}
                                onToggle={this.onToggleInputMode}
                            />
                        </>
                    )}
                </Row>

                <Row>
                    <AceEditor
                        ref={this.editorRef}
                        mode="text"
                        theme="github"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            tabSize: 2,
                            useSoftTabs: true,
                            showPrintMargin: false,
                            vScrollBarAlwaysVisible: false,
                            readOnly: this.state.debugMode,
                            highlightActiveLine: !this.state.debugMode,
                            fontSize: 14,
                        }}
                        width="100%"
                        height="400px"
                        markers={markers}
                        value={this.state.code}
                        onChange={this.onChangeCode}
                    />
                </Row>

                <Row className="brainfuck-bottom-buttons">
                    {this.props.submissionId ? (
                        <Button
                            variant="warning"
                            className="brainfuck-submit-button"
                            onClick={this.onClickSubmit}
                        >
                            Submit
                        </Button>
                    ) : (
                        <></>
                    )}
                </Row>

                <div className="brainfuck-io-section">
                    <Tabs
                        activeKey={this.state.activeIOTab}
                        transition={false}
                        onSelect={this.onSelectIOTab}
                    >
                        <Tab eventKey="input" title="Input">
                            <br />

                            {this.state.inputMode === IO_TAPE_MODE ? (
                                <BrainfuckMemoryTape
                                    mem={this.state.inputTapeMem}
                                    disabled={this.state.debugMode}
                                    isInput={true}
                                    onCellChange={this.onInputCellChange}
                                />
                            ) : (
                                <textarea
                                    className="brainfuck-io-input"
                                    disabled={this.state.debugMode}
                                    value={this.state.input}
                                    onChange={this.onChangeInput}
                                    onKeyDown={(e) =>
                                        cmdEnterToRun(e, this.runCode)
                                    }
                                />
                            )}
                        </Tab>
                        <Tab eventKey="output" title="Output">
                            <br />

                            {this.state.submissionResults ? (
                                <BrainfuckSubmissionResults
                                    {...this.state.submissionResults}
                                />
                            ) : (
                                <>
                                    {this.state.errorMessage ? (
                                        <Alert
                                            className="brainfuck-error-dialog"
                                            variant="danger"
                                        >
                                            {this.state.errorMessage}
                                        </Alert>
                                    ) : (
                                        <>
                                            {this.state.inputMode ===
                                            IO_TAPE_MODE ? (
                                                <BrainfuckMemoryTape
                                                    mem={
                                                        this.state.outputTapeMem
                                                    }
                                                />
                                            ) : (
                                                <pre className="brainfuck-io-output">
                                                    {this.state.output}
                                                </pre>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </>
        );
    }

    _messageForError(code, err) {
        if (err === null || err === undefined) {
            return null;
        }

        let errType = err.type,
            errorMessage = null;
        if (errType === BrainfuckInterpreter.ERROR_SYNTAX) {
            let loc = getCodeLocation(code, err.position);
            errorMessage = `${err.message} at line ${loc.line} column ${loc.col}`;
        } else if (errType === BrainfuckInterpreter.ERROR_TIMEOUT) {
            errorMessage = `${err.message} (${TIMEOUT_TIME / 1000} seconds)`;
        } else {
            errorMessage = `${err.message} (${errType} error)`;
        }
        return errorMessage;
    }

    onToggleInputMode = (mode) => {
        this.setState({
            inputMode: mode.value,
        });
    };

    onSelectIOTab = (key) => {
        this.setState({
            activeIOTab: key,
        });
    };

    onChangeCode = (code) => {
        this.setState({ code });

        const editorId = this.getEditorId();
        if (editorId) {
            LocalStorage.setItem(editorId, code);
        }
    };

    onChangeInput = (e) => {
        this.setState({ input: e.target.value });
    };

    _sanitizeTestResult(code, result) {
        result.errorMessage = this._messageForError(code, result.err);

        if (result.input === '') {
            result.input = null;
        }
        if (result.inputMem) {
            if (result.inputMem.length === 0) {
                result.inputMem = null;
            } else {
                result.inputMem = BrainfuckInterpreter.sanitizeMemory(
                    result.inputMem
                );
            }
        }

        if (result.expectedOutputMem) {
            result.expectedOutputMem = BrainfuckInterpreter.sanitizeMemory(
                result.expectedOutputMem
            );

            // hide printed output if it's blank
            if (result.output === '') {
                result.output = null;
            }
        } else {
            // hide output mem if the expected output is not a tape
            result.outputMem = null;
        }
    }

    onClickSubmit = (e) => {
        const code = this.state.code;

        axios
            .post('/submit/brainfuck/', {
                code,
                problemId: this.props.submissionId,
            })
            .then((res) => {
                let data = res.data;

                data.sampleTestResults.forEach((r) =>
                    this._sanitizeTestResult(code, r)
                );
                data.testResults.forEach((r) =>
                    this._sanitizeTestResult(code, r)
                );

                if (this.props.onSubmissionResults) {
                    this.props.onSubmissionResults(data);
                }

                this.setState({
                    activeIOTab: 'output',
                    submissionResults: data,
                });
            })
            .catch((err) => console.log(err?.response));
    };

    runCode = () => {
        // TODO:
        // * set editor, input to readonly while running the code
        // * what happens if the program infinite loops? (timeout? stop button?)
        // * scroll to output when done?

        let editor = this.editorRef.current.editor,
            code = editor.getSession().getValue(),
            errorMessage = null,
            outputState;

        let input = '',
            inputMem = null;

        if (this.state.inputMode === IO_TAPE_MODE) {
            inputMem = [...this.state.inputTapeMem];
        } else {
            input = this.state.input;
        }

        outputState = BrainfuckInterpreter.runWithTimeLimit(
            code,
            input,
            inputMem,
            TIMEOUT_TIME
        );

        if (outputState.err) {
            errorMessage = this._messageForError(code, outputState.err);
        }

        this.setState({
            activeIOTab: 'output',
            output: outputState.output,
            outputTapeMem: [...outputState.mem],
            errorMessage: errorMessage,
            submissionResults: null,
        });
    };

    debugCode = () => {
        let editor = this.editorRef.current.editor,
            code = editor.getSession().getValue(),
            input = '',
            inputMem = null;

        if (this.state.inputMode === IO_TAPE_MODE) {
            inputMem = [...this.state.inputTapeMem];
        } else {
            input = this.state.input;
        }

        let currentExecution = BrainfuckInterpreter.debug(
            code,
            input,
            inputMem
        );

        let errorMessage = null,
            activeIOTab = this.state.activeIOTab;
        if (currentExecution.err) {
            errorMessage = this._messageForError(code, currentExecution.err);
            activeIOTab = 'output';
        }

        this.setState({
            activeIOTab,
            debugMode: true,
            debugState: DEBUG_UNSTARTED,
            currentExecution: currentExecution,
            output: '',
            outputTapeMem: BrainfuckInterpreter.emptyMemory(),
            errorMessage: errorMessage,
            submissionResults: null,
        });
    };

    /**
     * Animates stepping through the debugger until paused, stopped, or the program terminates
     */
    _animateStepping() {
        if (
            this.state.debugState === DEBUG_STEPPING &&
            !this.state.currentExecution?.hasCompleted()
        ) {
            this.debugStep(null, true);
            setTimeout(this._animateStepping, this.state.stepDelay);
        }
    }

    debugContinue = () => {
        if (
            this.state.debugState === DEBUG_PAUSED ||
            this.state.debugState === DEBUG_UNSTARTED
        ) {
            this.setState(
                {
                    debugState: DEBUG_STEPPING,
                },
                () => {
                    this._animateStepping();
                }
            );
        } else {
            this.setState({
                debugState: DEBUG_PAUSED,
            });
        }
    };

    _scrollToSelectedMemoryCell = () => {
        let memoryTapes = document.getElementsByClassName(
            'brainfuck-memory-tape-wrapper'
        );
        for (let memoryTape of memoryTapes) {
            let selectedCells = memoryTape.getElementsByClassName(
                'brainfuck-memory-tape-selected-cell'
            );
            if (selectedCells.length > 0) {
                let selectedCell = selectedCells[0];

                // TODO: how much should this scroll by?
                // * center selected cell [current]
                // * selected cell is farthest left/right (depending on direction)

                // if selected cell is off to the left, scroll to the right
                if (selectedCell.offsetLeft < memoryTape.scrollLeft) {
                    memoryTape.scrollLeft =
                        selectedCell.offsetLeft - memoryTape.offsetWidth / 2;
                }
                // if selected cell is off to the right, scroll to the left
                else if (
                    selectedCell.offsetLeft + selectedCell.offsetWidth >
                    memoryTape.scrollLeft + memoryTape.offsetWidth
                ) {
                    memoryTape.scrollLeft =
                        selectedCell.offsetLeft + memoryTape.offsetWidth / 2;
                }
            }
        }
    };

    debugStep = (e, isAnimated) => {
        let bf = this.state.currentExecution;
        if (bf === null) {
            // quit if current execution is null for some reason
            return;
        }
        bf.step();

        // added so that 'Start' becomes 'Continue' if you press step first
        let debugState = this.state.debugState;
        if (debugState === DEBUG_UNSTARTED) {
            debugState = isAnimated ? DEBUG_STEPPING : DEBUG_PAUSED;
        }

        let errorMessage = null;
        if (bf.err) {
            errorMessage = this._messageForError(bf.code, bf.err);
        }

        let activeIOTab = this.state.activeIOTab;
        if (bf.hasCompleted()) {
            activeIOTab = 'output';
        }

        this.setState(
            {
                activeIOTab,
                output: bf.output,
                outputTapeMem: [...bf.mem],
                errorMessage: errorMessage,
                currentExecution: bf,
                debugState: debugState,
            },
            this._scrollToSelectedMemoryCell
        );
    };

    debugStop = () => {
        this.setState({
            debugMode: false,
            debugState: DEBUG_UNSTARTED,
            currentExecution: null,
        });
    };

    onInputCellChange = (index, value) => {
        let inputTapeMem = [...this.state.inputTapeMem],
            num = parseInt(value);

        if (Number.isNaN(num)) {
            num = 0;
        } else if (num >= BrainfuckInterpreter.NUM_INTS) {
            // TODO: change to max value or show an error?
            num = BrainfuckInterpreter.NUM_INTS - 1;
        }

        inputTapeMem[index] = num;

        this.setState({
            inputTapeMem: inputTapeMem,
        });
    };
}

export default BrainfuckIDE;
