import { Helmet } from 'react-helmet-async';
import EsoDeck from '../ui-components/cards/EsoDeck';
import { Route, Redirect, Switch } from 'react-router-dom';
import BrainfuckSandbox from '../interpreters/brainfuck/BrainfuckSandbox';
import IntercalSandbox from '../interpreters/intercal/IntercalSandbox';
import ChefSandbox from '../interpreters/chef/ChefSandbox';
import BefungeSandbox from '../interpreters/befunge/BefungeSandbox';

function withRoutes(Component) {
    return () => (
        <Switch>
            <Route path="/sandbox" exact component={Component} />

            <Route path="/sandbox/befunge" component={BefungeSandbox} />
            <Route path="/sandbox/brainfuck" component={BrainfuckSandbox} />
            <Route path="/sandbox/chef" component={ChefSandbox} />
            <Route path="/sandbox/intercal" component={IntercalSandbox} />

            <Redirect to="/sandbox" />
        </Switch>
    );
}

function Sandbox() {
    return (
        <div className="page-body">
            <Helmet>
                <title>Sandbox | EsoLab</title>
            </Helmet>

            <h1>Sandbox</h1>

            <EsoDeck className="challenges-module-deck">
                <EsoDeck.Card name="Befunge" linkTo="/sandbox/befunge" />
                <EsoDeck.Card name="Brainfuck" linkTo="/sandbox/brainfuck" />
                <EsoDeck.Card name="Chef" linkTo="/sandbox/chef" />
                <EsoDeck.Card name="INTERCAL" linkTo="/sandbox/intercal" />
            </EsoDeck>
        </div>
    );
}

export default withRoutes(Sandbox);
