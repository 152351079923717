import './brainfuck-sandbox.css';

import BrainfuckIDE from './BrainfuckIDE';
import { Helmet } from 'react-helmet-async';

function BrainfuckSandbox() {
    return (
        <div className="brainfuck-sandbox-container page-body">
            <Helmet>
                <title>Brainfuck | EsoLab</title>
            </Helmet>

            <BrainfuckIDE editorId="sandbox" />
        </div>
    );
}

export default BrainfuckSandbox;
