import { TutorialSection, TutorialPage } from '../Tutorial';

function BrainfuckTutorialSummary() {
    return (
        <TutorialSection key="summary">
            <TutorialPage title="Summary">
                <p>Congratulations, you are now a brainfuck programmer!</p>
                <p>
                    One last important thing to mention is that some parts of
                    the brainfuck language were intentionally left unspecified,
                    meaning that there exists some implementation-specific
                    behavior. The instruction set and overall structure is
                    always the same, but a few small details vary across
                    different implementations. Below is a list of these
                    implementation-specific details, as well as the behavior the
                    interpreter on this website uses.
                </p>
                <ul>
                    <li>Tape size: 256 cells</li>
                    <li>Integer size: 8 bits, or 256 possible integers</li>
                    <li>EOF (end of file/end of input): represented as 0</li>
                    <li>
                        Wrapping: both the memory tape and integers wrap around
                        on over/underflow
                    </li>
                    <li>Character set: extended ASCII with newlines as 10</li>
                </ul>
            </TutorialPage>
        </TutorialSection>
    );
}

export default BrainfuckTutorialSummary;
