import React from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';
import BefungeMode from './BefungeMode';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-github';

import './befunge-theme.css';
import './befunge.css';
import BrainfuckSubmissionResults from '../brainfuck/BrainfuckSubmissionResults';
import LocalStorage from '../../storage/LocalStorage';

import BefungeInterpreter from './BefungeInterpreter';
import cmdEnterToRun from '../cmdEnterToRun';

// enum for debugger states
const DEBUG_UNSTARTED = 1;
const DEBUG_STEPPING = 2;
const DEBUG_PAUSED = 3;

const MIN_DELAY = 30;
const MAX_DELAY = 1000;
const DEFAULT_DELAY = 500; // milliseconds

const TIMEOUT_TIME = 5000;

function StackComponent(props) {
    return (
        <div style={{ width: '100%' }}>
            <h4>Stack</h4>
            <div className="befunge-stack-wrapper">
                <div className="befunge-stack">
                    <span className="befunge-stack-bottom-label">Bottom</span>
                    {props.stack.map((x, i) => (
                        <span key={i} className="befunge-stack-cell">
                            {x}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
}

class BefungeIDE extends React.Component {
    _continueButtonString = () => {
        if (this.state.debugState === DEBUG_UNSTARTED) {
            return 'Start';
        } else if (this.state.debugState === DEBUG_STEPPING) {
            return 'Pause';
        } else {
            return 'Continue';
        }
    };

    getEditorId = () => {
        return `befunge-editor:${this.props.editorId}`;
    };

    constructor(props) {
        super(props);

        const { initState, editorId, defaultCode, defaultInput } = props;

        this.editorRef = React.createRef();

        if (initState) {
            this.state = { ...initState };
        } else {
            let code = defaultCode || '';
            if (editorId) {
                code = LocalStorage.getItem(this.getEditorId()) || code;
            }

            this.state = {
                code,
                activeIOTab: 'input',
                input: defaultInput || '',
                output: '',
                errorMessage: null,
                debugMode: false,
                currentExecution: null,
                debugState: DEBUG_UNSTARTED,
                stepDelay: DEFAULT_DELAY,
                submissionResults: null,
            };
        }

        this._animateStepping = this._animateStepping.bind(this);
    }

    componentDidMount() {
        const befungeMode = new BefungeMode();
        this.editorRef.current.editor.getSession().setMode(befungeMode);
        this.editorRef.current.editor.commands.addCommand({
            exec: this.runCode,
            bindKey: { mac: 'Command-Enter', win: 'Ctrl-Enter' },
        });
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        // issue #61: fixes bug where editor doesn't update properly when switching between challenges
        // TODO: look into better solutions, e.g. using redux
        if (prevProps.editorId !== this.props.editorId) {
            if (this.props.editorId) {
                const code = LocalStorage.getItem(this.getEditorId()) || '';
                this.setState({ code });
            }
        }
    }

    render() {
        const { debugMode } = this.state;

        // if debugging, highlight next instruction
        let markers = [];
        if (debugMode) {
            let pc = this.state.currentExecution.pc;

            markers.push({
                startRow: pc.y,
                startCol: pc.x,
                endRow: pc.y,
                endCol: pc.x + 1,
                className: 'befunge-current-character',
                type: 'text',
            });
        }
        markers.push({
            startRow: BefungeInterpreter.PLAYFIELD_HEIGHT - 1,
            startCol: 0,
            endRow: BefungeInterpreter.PLAYFIELD_HEIGHT - 1,
            endCol: BefungeInterpreter.PLAYFIELD_WIDTH - 1,
            className: 'befunge-playfield-bottom',
            type: 'fullLine',
        });

        // if debugging, hide cursor
        let editor = this.editorRef?.current?.editor;
        if (editor) {
            editor.renderer.$cursorLayer.element.style.opacity = debugMode
                ? 0
                : 1;
        }

        return (
            <>
                {debugMode ? (
                    <Row>
                        <StackComponent
                            stack={
                                this.state.currentExecution?.getStack() || []
                            }
                        />
                    </Row>
                ) : (
                    <></>
                )}
                <Row className="befunge-top-buttons">
                    {debugMode ? (
                        <>
                            <div>
                                <Button
                                    variant="primary"
                                    className="befunge-ide-button"
                                    disabled={
                                        debugMode &&
                                        this.state.currentExecution?.hasCompleted()
                                    }
                                    onClick={this.debugContinue}
                                >
                                    {this._continueButtonString()}
                                </Button>

                                <Button
                                    variant="success"
                                    className="befunge-ide-button"
                                    disabled={
                                        debugMode &&
                                        (this.state.currentExecution?.hasCompleted() ||
                                            this.state.debugState ===
                                                DEBUG_STEPPING)
                                    }
                                    onClick={this.debugStep}
                                >
                                    Step
                                </Button>

                                <Button
                                    variant="danger"
                                    className="befunge-ide-button"
                                    onClick={this.debugStop}
                                >
                                    Stop
                                </Button>
                            </div>

                            <div className="befunge-delay-slider">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>Delay</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type="range"
                                                    min={MIN_DELAY}
                                                    max={MAX_DELAY}
                                                    value={this.state.stepDelay}
                                                    onInput={(e) =>
                                                        this.setState({
                                                            stepDelay:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="primary"
                                className="befunge-ide-button"
                                onClick={this.runCode}
                            >
                                Run
                            </Button>
                            <Button
                                variant="success"
                                className="befunge-ide-button"
                                onClick={this.debugCode}
                            >
                                Debug
                            </Button>
                        </>
                    )}
                </Row>

                <Row>
                    <AceEditor
                        ref={this.editorRef}
                        mode="text"
                        theme="github"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            tabSize: 1,
                            useSoftTabs: true,
                            showPrintMargin: true,
                            printMarginColumn:
                                BefungeInterpreter.PLAYFIELD_WIDTH,
                            vScrollBarAlwaysVisible: false,
                            readOnly: debugMode,
                            highlightActiveLine: !debugMode,
                            highlightGutterLine: !debugMode,
                            fontSize: 14,
                        }}
                        width="100%"
                        height="400px"
                        markers={markers}
                        value={
                            debugMode
                                ? this.state.currentExecution.getPlayfieldAsString()
                                : this.state.code
                        }
                        onChange={this.onChangeCode}
                    />
                </Row>

                <Row className="befunge-bottom-buttons">
                    {this.props.submissionId ? (
                        <Button
                            variant="warning"
                            className="befunge-submit-button"
                            onClick={this.onClickSubmit}
                        >
                            Submit
                        </Button>
                    ) : (
                        <></>
                    )}
                </Row>

                <div className="befunge-io-section">
                    <Tabs
                        activeKey={this.state.activeIOTab}
                        transition={false}
                        onSelect={this.onSelectIOTab}
                    >
                        <Tab eventKey="input" title="Input">
                            <br />

                            <textarea
                                className="befunge-io-input"
                                disabled={debugMode}
                                value={this.state.input}
                                onChange={this.onChangeInput}
                                onKeyDown={(e) =>
                                    cmdEnterToRun(e, this.runCode)
                                }
                            />
                        </Tab>
                        <Tab eventKey="output" title="Output">
                            <br />

                            {this.state.submissionResults ? (
                                <BrainfuckSubmissionResults
                                    {...this.state.submissionResults}
                                />
                            ) : (
                                <>
                                    {this.state.errorMessage ? (
                                        <Alert
                                            className="befunge-error-dialog"
                                            variant="danger"
                                        >
                                            {this.state.errorMessage}
                                        </Alert>
                                    ) : (
                                        <pre className="befunge-io-output">
                                            {this.state.output}
                                        </pre>
                                    )}
                                </>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </>
        );
    }

    _messageForError(code, err) {
        if (err === null || err === undefined) {
            return null;
        }

        let errType = err.type,
            errorMessage = null;
        if (errType === BefungeInterpreter.ERROR_TIMEOUT) {
            errorMessage = `${err.message} (${TIMEOUT_TIME / 1000} seconds)`;
        } else if (
            errType === BefungeInterpreter.ERROR_SYNTAX ||
            errType === BefungeInterpreter.ERROR_ARITHMETIC
        ) {
            errorMessage = err.message;
        } else {
            errorMessage = `${err.message} (${errType} error)`;
        }
        return errorMessage;
    }

    onToggleInputMode = (mode) => {
        this.setState({
            inputMode: mode.value,
        });
    };

    onSelectIOTab = (key) => {
        this.setState({
            activeIOTab: key,
        });
    };

    onChangeCode = (code) => {
        this.setState({ code });

        const editorId = this.getEditorId();
        if (editorId) {
            LocalStorage.setItem(editorId, code);
        }
    };

    onChangeInput = (e) => {
        this.setState({ input: e.target.value });
    };

    _sanitizeTestResult(code, result) {
        // TODO
    }

    onClickSubmit = (e) => {
        const code = this.state.code;

        axios
            .post('/submit/befunge/', {
                code,
                problemId: this.props.submissionId,
            })
            .then((res) => {
                let data = res.data;

                data.sampleTestResults.forEach((r) =>
                    this._sanitizeTestResult(code, r)
                );
                data.testResults.forEach((r) =>
                    this._sanitizeTestResult(code, r)
                );

                if (this.props.onSubmissionResults) {
                    this.props.onSubmissionResults(data);
                }

                this.setState({
                    activeIOTab: 'output',
                    submissionResults: data,
                });
            })
            .catch((err) => console.log(err?.response));
    };

    runCode = () => {
        // TODO:
        // * set editor, input to readonly while running the code
        // * what happens if the program infinite loops? (timeout? stop button?)
        // * scroll to output when done?

        let editor = this.editorRef.current.editor,
            code = editor.getSession().getValue(),
            input = this.state.input,
            errorMessage = null,
            outputState;

        outputState = BefungeInterpreter.runWithTimeLimit(
            code,
            input,
            TIMEOUT_TIME
        );

        if (outputState.err) {
            errorMessage = this._messageForError(code, outputState.err);
        }

        this.setState({
            activeIOTab: 'output',
            output: outputState.output,
            errorMessage: errorMessage,
            submissionResults: null,
        });
    };

    debugCode = () => {
        let editor = this.editorRef.current.editor,
            code = editor.getSession().getValue(),
            input = this.state.input;

        let currentExecution = BefungeInterpreter.debug(code, input);

        let errorMessage = null,
            activeIOTab = this.state.activeIOTab;
        if (currentExecution.err) {
            errorMessage = this._messageForError(code, currentExecution.err);
            activeIOTab = 'output';
        }

        this.setState({
            activeIOTab,
            debugMode: true,
            debugState: DEBUG_UNSTARTED,
            currentExecution: currentExecution,
            output: '',
            errorMessage: errorMessage,
            submissionResults: null,
        });
    };

    /**
     * Animates stepping through the debugger until paused, stopped, or the program terminates
     */
    _animateStepping() {
        if (
            this.state.debugState === DEBUG_STEPPING &&
            !this.state.currentExecution?.hasCompleted()
        ) {
            this.debugStep(null, true);
            setTimeout(this._animateStepping, this.state.stepDelay);
        }
    }

    debugContinue = () => {
        if (
            this.state.debugState === DEBUG_PAUSED ||
            this.state.debugState === DEBUG_UNSTARTED
        ) {
            this.setState(
                {
                    debugState: DEBUG_STEPPING,
                },
                () => {
                    this._animateStepping();
                }
            );
        } else {
            this.setState({
                debugState: DEBUG_PAUSED,
            });
        }
    };

    _scrollStack = () => {
        let stacks = document.getElementsByClassName('befunge-stack-wrapper');
        for (let stack of stacks) {
            stack.scrollLeft = stack.offsetWidth;
        }
    };

    debugStep = (e, isAnimated) => {
        let bf = this.state.currentExecution;
        if (bf === null) {
            // quit if current execution is null for some reason
            return;
        }
        bf.step();

        // added so that 'Start' becomes 'Continue' if you press step first
        let debugState = this.state.debugState;
        if (debugState === DEBUG_UNSTARTED) {
            debugState = isAnimated ? DEBUG_STEPPING : DEBUG_PAUSED;
        }

        let errorMessage = null;
        if (bf.err) {
            errorMessage = this._messageForError(bf.code, bf.err);
        }

        let activeIOTab = this.state.activeIOTab;
        if (bf.hasCompleted()) {
            activeIOTab = 'output';
        }

        this.setState(
            {
                activeIOTab,
                output: bf.output,
                errorMessage: errorMessage,
                currentExecution: bf,
                debugState: debugState,
            },
            this._scrollStack
        );
    };

    debugStop = () => {
        this.setState({
            debugMode: false,
            debugState: DEBUG_UNSTARTED,
            currentExecution: null,
        });
    };
}

export default BefungeIDE;
