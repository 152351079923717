import './auth.css';

import React from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { registerUser, setUserLoading } from '../actions/authActions';
import { setErrors } from '../actions/errorActions';
import { Helmet } from 'react-helmet-async';

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            username: '',
            password: '',
            password2: '',
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    render() {
        const { auth, errors } = this.props;

        return (
            <div className="login-container">
                <Helmet>
                    <title>Register | EsoLab</title>
                </Helmet>

                <h1 className="login-title">Create Account</h1>

                {/* TODO: figure out if server error occurred */}
                {/* <Alert variant="danger">
                        An error occurred with your request
                    </Alert> */}

                <Form noValidate onSubmit={this.onSubmit}>
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={this.state.email}
                            onChange={this.onChange('email')}
                            isInvalid={errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username"
                            value={this.state.username}
                            onChange={this.onChange('username')}
                            isInvalid={errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.onChange('password')}
                            isInvalid={errors.password}
                        />
                        {errors.password ? (
                            <></>
                        ) : (
                            <Form.Text className="text-muted">
                                Password must be between 6 and 30 characters
                            </Form.Text>
                        )}
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            value={this.state.password2}
                            onChange={this.onChange('password2')}
                            isInvalid={
                                !this.validatePassword2() || errors.password2
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.password2 || 'Passwords must match'}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <button
                        className="login-submit-button"
                        type="submit"
                        disabled={auth.loading}
                    >
                        Sign Up
                    </button>
                </Form>

                {/* TODO: do we want this? */}
                {/* <p className="login-register-text">
                    Returning user? <a href="/login">Log In</a>
                </p> */}
            </div>
        );
    }

    onChange = (id) => (e) => {
        this.setState({
            [id]: e.target.value,
        });
        this.props.setErrors({});
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { email, username, password, password2 } = this.state,
            newUser = { email, username, password, password2 };

        this.props.registerUser(newUser, this.props.history);
    };

    validateEmail = () => {
        return true;
    };

    validatePassword = () => {
        return true;
    };

    validatePassword2 = () => {
        const { password, password2 } = this.state;
        return password === password2;
    };
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    registerUser,
    setUserLoading,
    setErrors,
})(Register);
