import './auth.css';

import React from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import { loginUser, setUserLoading } from '../actions/authActions';
import isEmpty from 'is-empty';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    render() {
        const { auth, errors } = this.props;

        return (
            <div className="login-container">
                <Helmet>
                    <title>Login | EsoLab</title>
                </Helmet>

                <h1 className="login-title">Log In</h1>

                {errors.login ? (
                    <Alert variant="danger">{errors.login}</Alert>
                ) : isEmpty(errors) ? (
                    <></>
                ) : (
                    <Alert variant="danger">
                        An error occurred with your request
                    </Alert>
                )}

                <Form noValidate onSubmit={this.onSubmit}>
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={this.state.email}
                            onChange={this.onChange('email')}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.onChange('password')}
                        />
                    </Form.Group>

                    {/* TODO: keep me logged in checkbox? */}

                    <button
                        className="login-submit-button"
                        type="submit"
                        disabled={auth.loading}
                    >
                        Log In
                    </button>
                </Form>

                <p className="login-register-text">
                    New to EsoLab? <Link to="/register">Sign Up</Link>
                </p>
            </div>
        );
    }

    onChange = (id) => (e) => {
        this.setState({ [id]: e.target.value });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { email, password } = this.state;

        this.props.setUserLoading(true);
        this.props.loginUser({ email, password });
    };
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, setUserLoading })(Login);
