import { CLEAR_ERRORS, GET_ERRORS } from './types';

export const setErrors = (errors) => {
    return {
        type: GET_ERRORS,
        payload: errors,
    };
};

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    };
};
