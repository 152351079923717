import React from 'react';
import { Tutorial } from '../Tutorial';

import './brainfuck-tutorial.css';
import BrainfuckTutorialIntroduction from './BrainfuckTutorialIntroduction';
import BrainfuckTutorialArithmetic from './BrainfuckTutorialArithmetic';
import BrainfuckTutorialLoops from './BrainfuckTutorialLoops';
import BrainfuckTutorialOutput from './BrainfuckTutorialOutput';
import BrainfuckTutorialInput from './BrainfuckTutorialInput';
import BrainfuckTutorialSummary from './BrainfuckTutorialSummary';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// TODO: save scroll position for each page
class BrainfuckTutorial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completedSet: {},
        };
    }

    markComplete = (id) => {
        let completedSet = { ...this.state.completedSet };
        completedSet[id] = true;
        this.setState({ completedSet });
    };

    componentDidMount() {
        const completedSet = this.state.completedSet;

        axios.get('/problems/tutorial/brainfuck').then((res) => {
            const { problems } = res.data;

            for (let problem of problems) {
                if (problem.solved) {
                    const { moduleId, collectionId, problemId } = problem,
                        fullProblemId = `${moduleId}/${collectionId}/${problemId}`;
                    completedSet[fullProblemId] = true;
                }
            }

            this.setState({ completedSet });
        });
    }

    render() {
        let completedSet = this.state.completedSet,
            markComplete = this.markComplete;

        let sections = [
            BrainfuckTutorialIntroduction,
            BrainfuckTutorialArithmetic,
            BrainfuckTutorialLoops,
            BrainfuckTutorialOutput,
            BrainfuckTutorialInput,
            BrainfuckTutorialSummary,
        ].map((Section) => Section(completedSet, markComplete));

        return (
            <>
                <Helmet>
                    <title>Brainfuck Tutorial | EsoLab</title>
                </Helmet>
                <Tutorial>{sections}</Tutorial>
            </>
        );
    }
}

export default BrainfuckTutorial;
