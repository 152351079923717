import './intercal.css';

import React from 'react';
import Table from 'react-bootstrap/Table';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

const c = (symbol, name) => {
    return { symbol, name };
};

const CHARACTERS = [
    c('.', 'spot'),
    c(':', 'two-spot'),
    c(',', 'tail'),
    c(';', 'hybrid'),
    c('#', 'mesh'),
    c('=', 'half-mesh'),
    c('’', 'spark'),
    c('‘', 'backspark'),
    c('!', 'wow'),
    c('?', 'what'),
    c('"', 'rabbit-ears'),
    c(null, 'rabbit'),
    c('|', 'spike'),
    c('%', 'double-oh-seven'),
    c('-', 'worm'),
    c('<', 'angle'),
    c('>', 'right angle'),
    c('(', 'wax'),
    c(')', 'wane'),
    c('[', 'U turn'),
    c(']', 'U turn back'),
    c('{', 'embrace'),
    c('}', 'bracelet'),
    c('*', 'splat'),
    c('&', 'ampersand'),
    c('V', 'book'),
    c(null, 'bookworm'),
    c('$', 'big money'),
    c('¢', 'change'),
    c('~', 'squiggle'),
];

const NUM_COLS = 6;

class IntercalCharacterTable extends React.Component {
    constructor(props) {
        super(props);

        const characters = CHARACTERS.map((c) => {
            return { ...c, nameVisible: false };
        });

        this.state = {
            characters,
        };
    }

    render() {
        const { characters } = this.state,
            rows = [],
            numRows = Math.ceil(characters.length / NUM_COLS);

        for (let i = 0; i < numRows; i++) {
            const cols = [];
            for (let j = 0; j < NUM_COLS; j++) {
                const index = i * NUM_COLS + j;
                if (index >= characters.length) break;
                const character = characters[index];

                cols.push(
                    <td
                        key={j}
                        className={classnames({
                            'intercal-character-flipped': character.nameVisible,
                        })}
                        onClick={this.onClickCell(index)}
                    >
                        <div className="intercal-character-symbol">
                            {character.symbol ? (
                                character.symbol
                            ) : (
                                <img
                                    src={`/intercal/${character.name}${
                                        character.nameVisible ? '-white' : ''
                                    }.png`}
                                    alt={`${character.name} symbol`}
                                />
                            )}
                        </div>

                        {character.nameVisible ? (
                            <div className="intercal-character-name">
                                {character.name}
                            </div>
                        ) : (
                            <></>
                        )}
                    </td>
                );
            }
            rows.push(<tr key={i}>{cols}</tr>);
        }

        return (
            <div className="page-body intercal-character-table-page">
                <h1 className="intercal-character-table-title text-center">
                    INTERCAL Character Table
                </h1>

                <Table className="intercal-character-table" bordered>
                    <tbody>{rows}</tbody>
                </Table>

                <Button
                    className="intercal-character-table-toggle-button"
                    variant="dark"
                    size="sm"
                    onClick={this.onClickToggleButton}
                >
                    toggle names
                </Button>
            </div>
        );
    }

    onClickCell = (index) => () => {
        const characters = [...this.state.characters];
        characters[index].nameVisible = !characters[index].nameVisible;

        this.setState({ characters });
    };

    onClickToggleButton = (e) => {
        const characters = [...this.state.characters];

        let showNames = false;
        for (let c of characters) {
            if (!c.nameVisible) {
                showNames = true;
                break;
            }
        }

        for (let c of characters) {
            c.nameVisible = showNames;
        }

        this.setState({ characters });
    };
}

export default IntercalCharacterTable;
