import './gravity.css';

import React from 'react';
import {
    CollisionRule,
    GravityExecution,
    PointParticle,
} from './GravityInterpreter';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const DEFAULT_AXIS_LENGTH = 10;
const POINT_SCALE_FACTOR = 50;
const DEFAULT_STEP_DELAY = 1;

const HELLO_WORLD_CODE = [
    '(0,0) : 2',
    '',
    '(1,1,1,1, 2) ->  3 :  72',
    '(1,1,1,1, 3) ->  4 : 101',
    '(1,1,1,1, 4) ->  5 : 108',
    '(1,1,1,1, 5) ->  6 : 108',
    '(1,1,1,1, 6) ->  7 : 111',
    '(1,1,1,1, 7) ->  8 :  32',
    '(1,1,1,1, 8) ->  9 : 119',
    '(1,1,1,1, 9) -> 10 : 111',
    '(1,1,1,1,10) -> 11 : 114',
    '(1,1,1,1,11) -> 12 : 108',
    '(1,1,1,1,12) ->  # : 100',
].join('\n');

class GravitySimulation extends React.Component {
    createHelloWorldProgram = () => {
        const g = new GravityExecution();
        g.points.push(new PointParticle(0, 0, 2));

        const L = this.state?.axisLength || DEFAULT_AXIS_LENGTH;

        for (let x = -L; x <= L; x++) {
            for (let y = -L; y <= L; y++) {
                if (x === 0 && y === 0) continue;
                g.points.push(new PointParticle(x, y, 1));
            }
        }

        g.rules.push(
            ...[
                new CollisionRule([1, 1, 1, 1, 2], null, 3, 72),
                new CollisionRule([1, 1, 1, 1, 3], null, 4, 101),
                new CollisionRule([1, 1, 1, 1, 4], null, 5, 108),
                new CollisionRule([1, 1, 1, 1, 5], null, 6, 108),
                new CollisionRule([1, 1, 1, 1, 6], null, 7, 111),
                new CollisionRule([1, 1, 1, 1, 7], null, 8, 32),
                new CollisionRule([1, 1, 1, 1, 8], null, 9, 119),
                new CollisionRule([1, 1, 1, 1, 9], null, 10, 111),
                new CollisionRule([1, 1, 1, 1, 10], null, 11, 114),
                new CollisionRule([1, 1, 1, 1, 11], null, 12, 108),
                new CollisionRule([1, 1, 1, 1, 12], null, '#', 100),
            ]
        );

        return g;
    };

    constructor(props) {
        super(props);

        // TODO: remove window properties
        const g = this.createHelloWorldProgram();
        window.g = g;

        window.drawGravity = this.drawGravity;

        window.stepGravity = (steps, groupSize) => {
            groupSize = groupSize || 1;
            function step() {
                for (let i = 0; i < groupSize; i++) {
                    if (steps === 0) return;
                    if (!g.step()) {
                        alert(g.output);
                        return;
                    }
                    steps--;
                }

                window.drawGravity();
                setTimeout(step, DEFAULT_STEP_DELAY);
            }

            setTimeout(step, 10);
        };

        this.state = {
            running: false,
            gravity: g,
            output: g.output,
            axisLength: DEFAULT_AXIS_LENGTH,
            stepDelay: DEFAULT_STEP_DELAY,
        };
    }

    componentDidMount() {
        this.drawGravity();
    }

    render() {
        const { running, gravity, output, axisLength, stepDelay } = this.state;
        return (
            <div className="page-body">
                <Helmet>
                    <title>Gravity Simulation | EsoLab</title>
                </Helmet>

                <h1 className="gravity-simulation-title">
                    Gravity Hello World Program
                </h1>

                <Row>
                    <Col sm={6}>
                        <canvas
                            id="gravityCanvas"
                            width={600}
                            height={600}
                            style={{ border: '1px solid black' }}
                        />
                    </Col>

                    <Col sm={6}>
                        <div>
                            <Button
                                className="gravity-simulation-control-button"
                                variant="primary"
                                onClick={this.runSimulation}
                                disabled={running}
                            >
                                Run
                            </Button>

                            <Button
                                className="gravity-simulation-control-button"
                                variant="primary"
                                onClick={this.resetSimulation}
                                disabled={running && !gravity.halted}
                            >
                                Reset
                            </Button>
                        </div>

                        <div
                            className="gravity-simulation-parameter-container"
                            style={{ display: 'none' }}
                        >
                            <h3>Parameters</h3>

                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        Axis Length
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="number"
                                    value={axisLength}
                                    onChange={this.onChangeAxisLength}
                                />
                            </InputGroup>

                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        Step Delay
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="number"
                                    value={stepDelay}
                                    onChange={this.onChangeStepDelay}
                                />
                            </InputGroup>
                        </div>

                        <div className="gravity-simulation-code">
                            <h3>Code</h3>
                            <textarea disabled value={HELLO_WORLD_CODE} />
                        </div>

                        <div className="gravity-simulation-output-container">
                            <h3>Output</h3>
                            <pre className="gravity-simulation-output">
                                {output}
                            </pre>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    onChangeAxisLength = (e) => {
        this.setState({
            axisLength: parseInt(e.target.value) || DEFAULT_AXIS_LENGTH,
        });
    };

    onChangeStepDelay = (e) => {
        this.setState({
            stepDelay: parseInt(e.target.value) || DEFAULT_STEP_DELAY,
        });
    };

    drawGravity = () => {
        const canvas = document.getElementById('gravityCanvas'),
            ctx = canvas.getContext('2d'),
            width = canvas.width,
            height = canvas.height;

        ctx.clearRect(0, 0, width, height);

        for (let p of this.state.gravity.points) {
            const x = p.x * POINT_SCALE_FACTOR + width / 2,
                y = height / 2 - p.y * POINT_SCALE_FACTOR;

            if (p.mass > 1) {
                ctx.fillStyle = 'red';
            } else {
                ctx.fillStyle = 'black';
            }

            ctx.beginPath();
            ctx.arc(x, y, 4, 0, 2 * Math.PI);
            ctx.fill();
        }
    };

    stepSimulation = () => {
        const { gravity } = this.state;
        if (!gravity.step()) return;
        this.setState({ output: gravity.output });
        this.drawGravity();
        setTimeout(this.stepSimulation, this.state.stepDelay);
    };

    runSimulation = () => {
        this.setState({ running: true });
        setTimeout(this.stepSimulation, 0);
    };

    resetSimulation = () => {
        this.setState(
            {
                running: false,
                gravity: this.createHelloWorldProgram(),
                output: '',
            },
            () => {
                console.log(this.state);
                this.drawGravity();
            }
        );
    };
}

export default GravitySimulation;
