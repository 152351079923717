import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function Feedback(title, className, content) {
    return (
        <div className={className}>
            <Helmet>
                <title>{title} | EsoLab</title>
            </Helmet>

            {content}
        </div>
    );
}

const ReportBug = Feedback(
    'Report a Bug',
    '',
    <iframe
        title="Bug Report Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSeJ59YKqmWIbyB7tBLQsXTDbZZbB-FvLQbJA3QqsUUO0HWX1g/viewform?embedded=true"
        width="100%"
        height="800"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        style={{ marginTop: '15px', maxHeight: 'calc(100vh - 150px)' }}
    >
        Loading…
    </iframe>
);

class FeedbackPage extends React.Component {
    render() {
        return Feedback(
            'Feedback',
            'page-body',
            <>
                <div>
                    <h2>Report a Bug</h2>
                    <p>
                        Found a bug? Something's not working right? Tell us{' '}
                        <Link to="/feedback/bug">here</Link>
                    </p>
                </div>

                <div style={{ marginTop: '2rem' }}>
                    <h2>Send Feedback</h2>
                    <p>
                        Let us know how we’re doing! Submit your comments,
                        questions, suggestions, or anything else you’d like us
                        to know below.
                    </p>
                    <iframe
                        title="Feedback Form"
                        src="https://docs.google.com/forms/d/e/1FAIpQLSeOKbOBWhjkblD713Ql3JL_4zfrIAFO9xmA27fEt_z-4gqSnQ/viewform?embedded=true"
                        width="100%"
                        height="500"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                    >
                        Loading…
                    </iframe>
                </div>
            </>
        );
    }

    static ReportBug = () => ReportBug;
}

export default FeedbackPage;
