import { Helmet } from 'react-helmet-async';

function AboutPage() {
    return (
        <div className="page-body">
            <Helmet>
                <title>About | EsoLab</title>
            </Helmet>

            <h1>About Us</h1>
            <p>We like esoteric programming.</p>
        </div>
    );
}

export default AboutPage;
