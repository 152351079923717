// error actions
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// auth actions
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// challenges actions
export const GET_MODULES = 'GET_MODULES';
export const SELECT_MODULE = 'SELECT_MODULE';
export const GET_MODULE = 'GET_MODULE';
export const SELECT_PROBLEM = 'SELECT_PROBLEM';
export const GET_PROBLEM = 'GET_PROBLEM';
