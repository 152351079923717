import './intercal.css';

import { Helmet } from 'react-helmet-async';
import IntercalIDE from './IntercalIDE';

function IntercalSandbox() {
    return (
        <div className="page-body intercal-sandbox-container">
            <Helmet>
                <title>INTERCAL | EsoLab</title>
            </Helmet>

            <h1 className="text-center">INTERCAL</h1>

            <IntercalIDE editorId="sandbox" />

            <p className="intercal-sandbox-references">
                Compiled using the{' '}
                <a
                    href="http://spiff.de/jintercal.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    J-INTERCAL
                </a>{' '}
                compiler.
                <br />
                Reference{' '}
                <a
                    href="http://catb.org/esr/intercal/ick.htm"
                    target="_blank"
                    rel="noreferrer"
                >
                    manual
                </a>
                , including a list of{' '}
                <a
                    href="http://catb.org/esr/intercal/ick.htm#Errors-and-Warnings"
                    target="_blank"
                    rel="noreferrer"
                >
                    what errors and warnings mean
                </a>
                .
            </p>
        </div>
    );
}

export default IntercalSandbox;
